import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { ApproveOrRejectUserProps } from "../model/admin.type";
import {
  BackfillAccountingDto,
  CreateNotificationDto,
  EnterpriseSettingDto,
  LiquidityDeliveryRequestDto,
  PatchOpportunityStatusDto,
  ReqAdminUserDto,
  ReqCreateOpportunityV2Dto,
  ReqMinerRatingDto,
  ReqProcessDemoOpportunityEvents,
  ReqUpdateOpportunityV2Dto,
  UpdateFundingApprovalStatusDto,
} from "../model/api";

interface UpdateOpportunityStatus extends PatchOpportunityStatusDto {
  id: string;
}

interface UpdateFundingApprovalStatus extends UpdateFundingApprovalStatusDto {
  id: string;
}

interface DataRoom {
  opportunityId: string;
}

const sendNotification = async (data: CreateNotificationDto) => {
  return await apig.admin.putAdminNotification(data);
};

const approveOrRejectOrganization = async (data: ApproveOrRejectUserProps) => {
  return await apig.admin.patchOrganizationStatus(data.userId, { approved: data.approved });
};

const updateOpportunityStatus = async (data: UpdateOpportunityStatus) => {
  return await apig.admin.patchAdminOpportunityStatus(data.id, { status: data.status });
};

const updateFundingApprovalStatus = async (data: UpdateFundingApprovalStatus) => {
  return await apig.opportunity.updateFundingApprovalStatus(data.id as string, { status: data.status });
};

const requestAccessToDataRoom = async (data: DataRoom) => {
  return apig.lp.requestAccessToDataRoom(data);
};

const updateLiquidityDelivery = async (data: LiquidityDeliveryRequestDto) => {
  return apig.admin.patchAdminLiquidityDelivery(data);
};

const updateOrganizationEnterpriseSettings = async ({ id, payload }: { id: string; payload: EnterpriseSettingDto }) => {
  return apig.enterpriseSettings.enterpriseSettingsControllerUpdate(id, payload);
};

const updateGroupDefaultPool = async (data: { uuid: string; defaultPool: string }) => {
  return await apig.miningProxy.updateGroupDefaultPool(data);
};

const backfillAccounting = async (params: BackfillAccountingDto) => {
  return (await apig.transaction.backfillAccountingDetails(params)).data;
};

const useMutationAdminApproveOrRejectOrganization = () => {
  return useMutation((data: ApproveOrRejectUserProps) => approveOrRejectOrganization(data));
};

const useMutationAdminSendNotification = () => {
  return useMutation((data: CreateNotificationDto) => sendNotification(data));
};

const useMutationUpdateOpportunityStatus = () => {
  return useMutation((data: UpdateOpportunityStatus) => updateOpportunityStatus(data));
};

const useMutationUpdateFundingStatus = () => {
  return useMutation((data: UpdateFundingApprovalStatus) => updateFundingApprovalStatus(data));
};

const useMutationRequestAccessToDataRoom = () => {
  return useMutation((data: DataRoom) => requestAccessToDataRoom(data));
};

const useMutationLiquidityDelivery = () => {
  return useMutation((data: LiquidityDeliveryRequestDto) => updateLiquidityDelivery(data));
};

const useMutationOrganizationEnterpriseSettings = () => {
  return useMutation(({ id, payload }: { id: string; payload: EnterpriseSettingDto }) =>
    updateOrganizationEnterpriseSettings({ id, payload })
  );
};

const useMutationAdminGenerateFileUrl = () => {
  return useMutation((fileId: string) => apig.admin.generateFileSignedUrl(fileId));
};

const useMutationMinerRating = () => {
  return useMutation((data: ReqMinerRatingDto) => apig.admin.updateMinerRating(data));
};

const useMutationCreateVirtualOpportunity = () => {
  return useMutation((data: ReqCreateOpportunityV2Dto) => apig.admin.createOpportunityV2(data));
};

const useMutationProcessDemoOpportunity = () => {
  return useMutation((data: ReqProcessDemoOpportunityEvents) => apig.admin.processDemoOpportunityEvents(data));
};

const useMutationUpdateOpportunityV2 = () => {
  return useMutation((data: ReqUpdateOpportunityV2Dto) => apig.admin.updateOpportunityV2(data));
};

const useMutationDumpMinerData = () => {
  return useMutation({ mutationFn: (minerId: string) => apig.admin.dumpMinerData(minerId) });
};

const useMutationAddAdminUser = () => {
  return useMutation((input: ReqAdminUserDto) => apig.admin.createAdminUser(input));
};

const useMutationApproveMinerOnboarding = () => {
  return useMutation((minerId: string) => apig.admin.approveMinerOnboarding(minerId));
};

const useMutationRejectMinerOnboarding = () => {
  return useMutation((minerId: string) => apig.admin.rejectMinerOnboarding(minerId));
};

const useMutationAdminKym2ApproveMinerKyc = () => {
  return useMutation((minerId: string) => apig.admin.approveMinerKycAndGenerateWallet({ minerId }));
};

const useMutationUpdateGroupDefaultPool = () => {
  return useMutation((data: { uuid: string; defaultPool: string }) => updateGroupDefaultPool(data));
};

const useMutationBackfillAccounting = () => {
  return useMutation((params: BackfillAccountingDto) => backfillAccounting(params));
};

export {
  useMutationAddAdminUser,
  useMutationAdminApproveOrRejectOrganization,
  useMutationAdminGenerateFileUrl,
  useMutationAdminKym2ApproveMinerKyc,
  useMutationAdminSendNotification,
  useMutationApproveMinerOnboarding,
  useMutationBackfillAccounting,
  useMutationCreateVirtualOpportunity,
  useMutationDumpMinerData,
  useMutationLiquidityDelivery,
  useMutationMinerRating,
  useMutationOrganizationEnterpriseSettings,
  useMutationProcessDemoOpportunity,
  useMutationRejectMinerOnboarding,
  useMutationRequestAccessToDataRoom,
  useMutationUpdateFundingStatus,
  useMutationUpdateGroupDefaultPool,
  useMutationUpdateOpportunityStatus,
  useMutationUpdateOpportunityV2,
};
