import {
  OpportunityProgressDto,
  OpportunityProgressDtoCollateralStatusEnum,
  OpportunityProgressDtoCurrentStageEnum,
} from "../../../app/model/api";

export type StageType = "SETUP" | "ACTIVE" | "SETTLEMENT";
export const SETUP_STEPS = [
  OpportunityProgressDtoCurrentStageEnum.COMMIT_ADVANCE_AMOUNT,
  OpportunityProgressDtoCurrentStageEnum.SIGN_AGREEMENT,
  OpportunityProgressDtoCurrentStageEnum.PROVIDE_ADVANCE_AMOUNT,
  OpportunityProgressDtoCurrentStageEnum.PROVIDE_COLLATERAL,
  OpportunityProgressDtoCurrentStageEnum.PROVIDE_HASHRATE,
  OpportunityProgressDtoCurrentStageEnum.CONFIRM_OPPORTUNITY_START,
] as const;
export const ACTIVE_STEPS = [OpportunityProgressDtoCurrentStageEnum.DELIVER_SUFFICIENT_REWARDS] as const;
export const SETTLEMENT_STEPS = [
  OpportunityProgressDtoCurrentStageEnum.CEASE_HASHRATE_STREAMING,
  OpportunityProgressDtoCurrentStageEnum.RETURN_COLLATERAL,
  OpportunityProgressDtoCurrentStageEnum.RETURN_EXCESS_REWARDS,
] as const;
export const FINAL_STEPS = [
  OpportunityProgressDtoCurrentStageEnum.DONE,
  OpportunityProgressDtoCurrentStageEnum.CANCELLED,
  OpportunityProgressDtoCurrentStageEnum.DEFAULTED,
] as const;
export type SetupSteps = (typeof SETUP_STEPS)[number];
export type ActiveSteps = (typeof ACTIVE_STEPS)[number];
export type SettlementSteps = (typeof SETTLEMENT_STEPS)[number];
export type InProgressSteps = SetupSteps | ActiveSteps | SettlementSteps;
export type FinalSteps = (typeof FINAL_STEPS)[number];
export type AllSteps = InProgressSteps | FinalSteps;
export type Party = "Miner" | "LP" | "Block Green";

export const isInProgressStep = (step: AllSteps): step is InProgressSteps => {
  return !FINAL_STEPS.includes(step as FinalSteps);
};

export const isActiveStep = (step: AllSteps): step is ActiveSteps => {
  return ACTIVE_STEPS.includes(step as ActiveSteps);
};

export const isFinalStep = (step: AllSteps): step is FinalSteps => {
  return FINAL_STEPS.includes(step as FinalSteps);
};

export const STEP_METADATA: Record<InProgressSteps, { label: string; party: Party }> = {
  [OpportunityProgressDtoCurrentStageEnum.COMMIT_ADVANCE_AMOUNT]: { label: "Commit advance amount", party: "LP" },
  [OpportunityProgressDtoCurrentStageEnum.SIGN_AGREEMENT]: { label: "Sign agreement", party: "LP" },
  [OpportunityProgressDtoCurrentStageEnum.PROVIDE_ADVANCE_AMOUNT]: { label: "Provide advance amount", party: "LP" },
  [OpportunityProgressDtoCurrentStageEnum.PROVIDE_COLLATERAL]: { label: "Provide collateral", party: "Miner" },
  [OpportunityProgressDtoCurrentStageEnum.PROVIDE_HASHRATE]: { label: "Provide hashrate", party: "Miner" },
  [OpportunityProgressDtoCurrentStageEnum.CONFIRM_OPPORTUNITY_START]: {
    label: "Confirm opportunity start",
    party: "Block Green",
  },
  [OpportunityProgressDtoCurrentStageEnum.DELIVER_SUFFICIENT_REWARDS]: {
    label: "Deliver sufficient rewards",
    party: "Miner",
  },
  [OpportunityProgressDtoCurrentStageEnum.CEASE_HASHRATE_STREAMING]: {
    label: "Cease hashrate streaming",
    party: "Miner",
  },
  [OpportunityProgressDtoCurrentStageEnum.RETURN_COLLATERAL]: { label: "Return collateral", party: "Block Green" },
  [OpportunityProgressDtoCurrentStageEnum.RETURN_EXCESS_REWARDS]: {
    label: "Return excess rewards",
    party: "Block Green",
  },
} as const;

export const STAGE_STEPS: Record<StageType, readonly InProgressSteps[]> = {
  SETUP: SETUP_STEPS,
  ACTIVE: ACTIVE_STEPS,
  SETTLEMENT: SETTLEMENT_STEPS,
} as const;

export interface OpportunityStatusProgressProps {
  opportunityId: string;
  className?: string;
}
