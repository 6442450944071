import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import toast from "react-hot-toast";
import { allowDemoHandling } from "../../../../app/config/auth0/config";
import { MINER_ORGANIZATION_BASIC_INFO_ROUTE } from "../../../../app/constants";
import { PatchOpportunityStatusDtoStatusEnum, ResOpportunityDetailsV2DtoStatusEnum } from "../../../../app/model/api";
import { useMutationUpdateOpportunityStatus } from "../../../../app/query/useMutationAdmin";
import { useGetAdminOpportunityDetails } from "../../../../app/query/useQueryGetAdmin";
import { MuiFullscreenLoadingBackdrop } from "../../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";
import { StyledTableRow } from "../../commons";
import { SyntheticStreamingForm } from "./SyntheticStreamingForm";
import { MinerOpportunityFormReadonly } from "./MinerOpportunityFormReadonly";
import { OpportunityStatusProgress } from "../../../../components/molecules/OpportunityStatusProgress";

const RES_OPPORTUNITY_DETAILS_V2_DTO_STATUS_ENUM = [
  ResOpportunityDetailsV2DtoStatusEnum.Defaulted,
  ResOpportunityDetailsV2DtoStatusEnum.Cancelled,
  ResOpportunityDetailsV2DtoStatusEnum.Active,
] as const;

const ResOpportunityDetailsV2DtoStatusEnumToOpportunityStatus: Record<
  ResOpportunityDetailsV2DtoStatusEnum,
  PatchOpportunityStatusDtoStatusEnum
> = {
  [ResOpportunityDetailsV2DtoStatusEnum.AwaitingFunding]: PatchOpportunityStatusDtoStatusEnum.AwaitingFunding,
  [ResOpportunityDetailsV2DtoStatusEnum.Defaulted]: PatchOpportunityStatusDtoStatusEnum.Defaulted,
  [ResOpportunityDetailsV2DtoStatusEnum.Cancelled]: PatchOpportunityStatusDtoStatusEnum.Cancelled,
  [ResOpportunityDetailsV2DtoStatusEnum.Active]: PatchOpportunityStatusDtoStatusEnum.Active,
  [ResOpportunityDetailsV2DtoStatusEnum.AwaitingCollateral]: PatchOpportunityStatusDtoStatusEnum.AwaitingCollateral,
  [ResOpportunityDetailsV2DtoStatusEnum.Draft]: PatchOpportunityStatusDtoStatusEnum.Draft,
  [ResOpportunityDetailsV2DtoStatusEnum.Settled]: PatchOpportunityStatusDtoStatusEnum.Settled,
  [ResOpportunityDetailsV2DtoStatusEnum.Closed]: PatchOpportunityStatusDtoStatusEnum.Closed,
} as const;

export const ConfirmationModal = ({
  question,
  handleApprove,
  handleCancel,
}: {
  question: string;
  handleApprove: () => void;
  handleCancel: () => void;
}) => {
  return (
    <Dialog open={!!question} onClose={() => handleCancel}>
      <DialogTitle id="alert-dialog-title">{"Confirm action"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleApprove}>Yes</Button>
        <Button onClick={handleCancel} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AdminOpportunityEdit = ({ opportunityId }: { opportunityId: string }) => {
  const [modalPayload, setModalPaylod] = React.useState<
    | undefined
    | {
        newStatus:
          | ResOpportunityDetailsV2DtoStatusEnum.AwaitingFunding
          | ResOpportunityDetailsV2DtoStatusEnum.Defaulted
          | ResOpportunityDetailsV2DtoStatusEnum.Cancelled
          | ResOpportunityDetailsV2DtoStatusEnum.Active;
        question: string;
      }
  >(undefined);

  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutationUpdateOpportunityStatus();

  const { data, isFetching, refetch } = useGetAdminOpportunityDetails(opportunityId ?? "");

  const onRefetch = () => {
    refetch();
    setDestroyKey(!destroyKey); // force the component to be rebuilt
    setEdit(false);
  };

  const handleUpdateStatus = async (status: ResOpportunityDetailsV2DtoStatusEnum) => {
    try {
      await updateStatus({
        id: opportunityId,
        status: ResOpportunityDetailsV2DtoStatusEnumToOpportunityStatus[status],
      });
      onRefetch();
    } catch (error) {
      toast.error("There has been an error, please refresh and try again.");
    }
  };

  const handleClose = (
    newStatus:
      | undefined
      | ResOpportunityDetailsV2DtoStatusEnum.AwaitingFunding
      | ResOpportunityDetailsV2DtoStatusEnum.Defaulted
      | ResOpportunityDetailsV2DtoStatusEnum.Cancelled
      | ResOpportunityDetailsV2DtoStatusEnum.Active
  ) => {
    setModalPaylod(undefined);
    if (newStatus) {
      handleUpdateStatus(newStatus);
    }
  };

  const payload = data?.data;
  const isEditable = payload?.status && payload.status === "Draft";

  const minerPath = payload?.minerId ? MINER_ORGANIZATION_BASIC_INFO_ROUTE.replace(":id", payload?.minerId ?? "") : "";

  const infoRows: { title: string; value: JSX.Element | null }[] = [
    {
      title: "Miner",
      value: (
        <Button
          sx={{ height: 20, padding: 0, "&:hover": { textDecoration: "underline" } }}
          target="_blank"
          rel="noreferrer"
          href={minerPath}
          variant="text"
        >
          {payload?.minerCompanyName ?? ""}
        </Button>
      ),
    },
    {
      title: "SynHashPool address",
      value: <Typography noWrap>{payload?.walletInfo.synHashPoolAddress ?? ""}</Typography>,
    },
    { title: "Reward address", value: <Typography noWrap>{payload?.walletInfo.rewardAddress ?? ""}</Typography> },
    {
      title: "Collateral address",
      value: <Typography noWrap>{payload?.walletInfo.collateralAddress ?? ""}</Typography>,
    },
  ];

  return isFetching || !payload ? (
    <Stack>
      <Skeleton width="100%" height={100}></Skeleton>
      <Skeleton width="100%" height={200}></Skeleton>
    </Stack>
  ) : (
    <Box width={"100%"} key={destroyKey.toString()} mt={1}>
      <MuiFullscreenLoadingBackdrop isOpen={isUpdatingStatus} />
      <OpportunityStatusProgress opportunityId={opportunityId} />
      {modalPayload ? (
        <ConfirmationModal
          question={modalPayload.question}
          handleApprove={() => handleClose(modalPayload.newStatus)}
          handleCancel={() => handleClose(undefined)}
        ></ConfirmationModal>
      ) : (
        <></>
      )}
      {payload.isDemo && allowDemoHandling && (
        <Alert variant="filled" color="warning">
          This is a demo opportunity. No auto changes will be performed on it, use the simulator to update it.
        </Alert>
      )}
      <Grid container item xs={12} mt={2}>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <StyledTableRow key={"th-op-info-0"}>
                <TableCell size="small" align="center">
                  Status
                </TableCell>
                <TableCell size="small" align="center">
                  Creation date
                </TableCell>
                <TableCell size="small" align="center">
                  Publish date
                </TableCell>
                <TableCell size="small" align="center">
                  Active since
                </TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <TableRow key={"row-dates-info-1"}>
                <TableCell size="small" align="center">
                  {payload.status}
                </TableCell>
                <TableCell size="small" align="center">
                  {format(new Date(payload.creationDate), "dd MMM yyyy")}
                </TableCell>
                <TableCell size="small" align="center">
                  {payload.publishDate ? format(new Date(payload.publishDate), "dd MMM yyyy") : "-"}
                </TableCell>
                <TableCell size="small" align="center">
                  {payload.activeFromDate ? format(new Date(payload.activeFromDate), "dd MMM yyyy") : "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {!payload.isDemo && (
        <Grid container item xs={12} alignItems={"center"} spacing={1} mt={2}>
          {payload?.status === "Draft" && (
            <Button
              variant="contained"
              color={"error"}
              sx={{ ml: 1 }}
              onClick={() =>
                setModalPaylod({
                  question: "Are you sure you want to publish this opportunity?",
                  newStatus: ResOpportunityDetailsV2DtoStatusEnum.AwaitingFunding,
                })
              }
            >
              Publish
            </Button>
          )}

          {RES_OPPORTUNITY_DETAILS_V2_DTO_STATUS_ENUM.filter((item) => item !== payload.status).map((status) => (
            <Button
              onClick={() =>
                setModalPaylod({
                  question: `Are you sure you want to change this opportunity's status to ${status}?`,
                  newStatus: status,
                })
              }
              sx={{ ml: 1 }}
              variant="contained"
              color="warning"
              key={`button-${status}`}
            >
              Change to {status}
            </Button>
          ))}
        </Grid>
      )}

      <Grid container item alignItems={"center"}>
        <Grid container item xs={12} md={6} alignItems={"center"} spacing={1} pl={1} mt={2}>
          {isEditable && !edit ? (
            <Button onClick={() => setEdit(true && isEditable)} color="primary">
              <EditNoteIcon sx={{ mr: 1 }} />
              Edit
            </Button>
          ) : null}
          {edit ? (
            <Button onClick={() => setEdit(false)} color="primary">
              Cancel
            </Button>
          ) : null}
        </Grid>
      </Grid>
      {!edit || !isEditable ? <MinerOpportunityFormReadonly opportunity={payload.opportunity} /> : null}
      {isEditable && edit ? (
        <Box mt={2}>
          <SyntheticStreamingForm
            minerId={payload.minerId}
            opportunity={payload.opportunity}
            triggerRefetch={onRefetch}
          ></SyntheticStreamingForm>
        </Box>
      ) : (
        <></>
      )}
      <Grid container item sm={12} md={6} mt={2}>
        <Typography variant="subtitle1"> Miner & wallets</Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableBody>
              {infoRows.map((row, index) => (
                <StyledTableRow key={"row-op-info-" + index}>
                  <TableCell size="small" align="left" width={300}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      {row.title}
                    </Typography>
                  </TableCell>
                  <TableCell size="small" align="left">
                    {row.value}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};
