import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";
import DisclaimerLayout from "../../layout/disclaimer";
import KYCLayout from "../../layout/kyc";
import MainLayout from "../../layout/main";
import { OnboardingLayout } from "../../layout/onboarding";
import Account from "../../pages/Account";
import { AdminToolingTabs, HashrateModel, MinerDetailTabs } from "../../pages/Admin";
import { AdminOpportunitiesPage } from "../../pages/Admin/AdminOpportunitiesPage";
import { AdminOpportunityTabs } from "../../pages/Admin/MinerDetails/Opportunity/AdminOpportunityTabs";
import { AdminOrganizations } from "../../pages/Admin/Organizations/AdminOrganizations";
import Disclaimer from "../../pages/Disclaimer";
import { DocumentApproval } from "../../pages/DocumentApproval";
import { EnterpriseTabs } from "../../pages/Enterprise/EnterpriseTabs";
import { ENTERPRISE_TABS } from "../../pages/Enterprise/enterpriseTabList";
import ErrorPage from "../../pages/ErrorPage";
import { KymTabs } from "../../pages/KYM";
import { LiquidityDeliveryPage } from "../../pages/LiquidityDelivery";
import { AdminPageAllLoans } from "../../pages/Loans/admin/AdminPageAllLoans";
import { AdminPageCreateLoan } from "../../pages/Loans/admin/AdminPageCreateLoan";
import { AdminPageLoanTabs } from "../../pages/Loans/admin/AdminPageLoanTabs";
import { LpPageLoanDetails } from "../../pages/Loans/lp/LpPageLoanDetails";
import { LpPageMyLoans } from "../../pages/Loans/lp/LpPageMyLoans";
import { MinerPageLoanDetails } from "../../pages/Loans/miner/MinerPageLoanDetails";
import { MinerPageMyLoans } from "../../pages/Loans/miner/MinerPageMyLoans";
import { Logout } from "../../pages/Logout/Logout";
import { LpOpportunities } from "../../pages/LpOpportunities/LpOpportunities";
import { LpOrganizationDetails } from "../../pages/LpOrganizationDetails/LpOrganizationDetails";
import { LpPortfolio } from "../../pages/LpPortfolio/LpPortfolio";
import { MinerOpportunities } from "../../pages/MinerOpportunities/MinerOpportunities";
import { PreviewLeaseDetailsLP } from "../../pages/OpportunityDetailsLP/PreviewLeaseDetails";
import { PreviewLoanDetailsLP } from "../../pages/OpportunityDetailsLP/PreviewLoanDetails";
import { PreviewStreamingDetailsLP } from "../../pages/OpportunityDetailsLP/PreviewStreamingDetails";
import { StreamingDetailsLP } from "../../pages/OpportunityDetailsLP/StreamingDetails";
import OpportunityDetailsMiner from "../../pages/OpportunityDetailsMiner";
import ThankYou from "../../pages/ThankYou";
import { WalletTabs } from "../../pages/Wallet/WalletTabs";
import { Onboarding } from "../../pages/onboarding";
import {
  ACCOUNT_ROUTE,
  ADMIN_DOCUMENT_APPROVAL_ROUTE,
  ADMIN_TABS_TOOLING,
  DISCLAIMER_ROUTE,
  ENTRYFORM_LP_ROUTE,
  ENTRYFORM_MINER_ROUTE,
  HOME_ROUTE,
  KYM2_ADMIN_OPPORTUNITY_TABS,
  KYM2_MINER_DETAILS_TABS,
  KYM2_TABS,
  LIQUIDITY_DELIVERY_ROUTE,
  LOGOUT,
  ONNBOARDING_ROUTE,
  OPPORTUNITIES_DETAILS_LP_ROUTE,
  OPPORTUNITY_DETAILS_ROUTE,
  ORGANIZATION_DETAILS_ROUTE,
  ORGANIZATION_ROUTE,
  PREVIEW_LEASE_DETAILS_LP_ROUTE,
  PREVIEW_LOAN_DETAILS_LP_ROUTE,
  PREVIEW_STREAMING_DETAILS_LP_ROUTE,
  PROXY_MANAGEMENT_ROUTE,
  ROUTES_LOANS,
  ROUTE_ADMIN_MINING_PROXY,
  ROUTE_ADMIN_STREAMING_PHYSICAL,
  ROUTE_ADMIN_STREAMING_SYNTHETIC,
  ROUTE_LP_PORTFOLIO,
  RoutesHashRatePrediction,
  THANK_YOU_ROUTE,
  WALLET_DEPOSIT_ROUTE,
  WALLET_ROUTE,
  WALLET_WITHDRAW_ROUTE,
  WHITELIST_ADDRESS_ROUTE,
} from "../constants";
import {
  AuthUserResponseDto,
  AuthUserResponseDtoOnboardingStatusEnum,
  AuthUserResponseDtoUserTypeEnum,
} from "../model/api";
import ProtectedRoute from "./ProtectedRoute";
import { AdminAllOpportunitiesPage } from "../../pages/Admin/AdminAllOpportunitiesPage";
import { ProxyManagement } from "../../pages/proxy/ProxyManagement";
import { AdminPhysicalOpportunityTabs } from "../../pages/Admin/MinerDetails/Opportunity/AdminPhysicalOpportunityTabs";
import { ADMIN_PHYSICAL_OPPORTUNITY_TABS } from "../constants/sectionTabsPhysicalOpportunity";
import { AdminMiningProxyPage } from "../../pages/Admin/MiningProxy/AdminMiningProxyPage";
import BackfillAccountingPage from "../../pages/Admin/BackfillAccounting/BackfillAccountingPage";

const selectLayout = (user: AuthUserResponseDto | null | undefined): JSX.Element => {
  if (!user?.onboardingStatus) {
    return <MainLayout />;
  } else if (
    user.userType === AuthUserResponseDtoUserTypeEnum.Miner &&
    user?.onboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYCSubmittedRepresentative
  )
    return <MainLayout />;
  else if (
    [
      AuthUserResponseDtoOnboardingStatusEnum.EntrypointSubmitted,
      AuthUserResponseDtoOnboardingStatusEnum.KYCSubmittedCompany,
      AuthUserResponseDtoOnboardingStatusEnum.KYMSubmittedGeneral,
    ].includes(user.onboardingStatus)
  )
    return <KYCLayout />;
  else return <MainLayout />;
};

const COMMON_LP_AND_MINER_ROUTE = [
  {
    path: ACCOUNT_ROUTE,
    element: <Account />,
  },
  {
    path: WALLET_ROUTE,
    element: <WalletTabs />,
  },
  {
    path: WALLET_WITHDRAW_ROUTE,
    element: <WalletTabs />,
  },
  {
    path: WALLET_DEPOSIT_ROUTE,
    element: <WalletTabs />,
  },
  {
    path: WHITELIST_ADDRESS_ROUTE,
    element: <WalletTabs />,
  },
  ...ENTERPRISE_TABS.map((entry) => ({ path: entry.path, element: <EnterpriseTabs /> })),
];

const MINER_ROUTES = [
  ...COMMON_LP_AND_MINER_ROUTE,
  {
    path: HOME_ROUTE,
    element: <MinerOpportunities />,
    index: true,
  },
  {
    path: OPPORTUNITY_DETAILS_ROUTE,
    element: <OpportunityDetailsMiner />,
  },
  {
    path: ROUTES_LOANS.Miner.Loans,
    element: <MinerPageMyLoans />,
    index: true,
  },
  {
    path: ROUTES_LOANS.Miner.LoanDetails,
    element: <MinerPageLoanDetails />,
    index: true,
  },
  {
    path: PROXY_MANAGEMENT_ROUTE,
    element: <ProxyManagement />,
  },
  ...KYM2_TABS.map((entry) => ({ path: entry.path, element: <KymTabs /> })),
];

const LP_ROUTES = [
  ...COMMON_LP_AND_MINER_ROUTE,
  {
    path: ROUTE_LP_PORTFOLIO,
    element: <LpPortfolio />,
    index: true,
  },
  {
    path: HOME_ROUTE,
    element: <LpOpportunities />,
  },
  {
    path: ROUTES_LOANS.LP.Loans,
    element: <LpPageMyLoans />,
    index: true,
  },
  {
    path: ROUTES_LOANS.LP.LoanDetails,
    element: <LpPageLoanDetails />,
    index: true,
  },
  {
    path: OPPORTUNITIES_DETAILS_LP_ROUTE,
    element: <StreamingDetailsLP />,
  },
  {
    path: PREVIEW_STREAMING_DETAILS_LP_ROUTE,
    element: <PreviewStreamingDetailsLP />,
  },
  {
    path: PREVIEW_LOAN_DETAILS_LP_ROUTE,
    element: <PreviewLoanDetailsLP />,
  },
  {
    path: PREVIEW_LEASE_DETAILS_LP_ROUTE,
    element: <PreviewLeaseDetailsLP />,
  },
];

const ADMIN_ROUTES = [
  {
    path: HOME_ROUTE,
    element: <AdminAllOpportunitiesPage />,
    index: true,
  },
  {
    path: "backfill-accounting",
    element: <BackfillAccountingPage />,
  },
  {
    path: ROUTE_ADMIN_STREAMING_SYNTHETIC,
    element: <AdminOpportunitiesPage />,
    index: true,
  },
  {
    path: ROUTES_LOANS.Admin.Loans,
    element: <AdminPageAllLoans />,
    index: true,
  },
  {
    path: ROUTES_LOANS.Admin.CreateLoan,
    element: <AdminPageCreateLoan />,
    index: true,
  },
  {
    path: ROUTES_LOANS.Admin.LoanDetails,
    element: <AdminPageLoanTabs />,
    index: true,
  },
  {
    path: ROUTES_LOANS.Admin.LoanPaymentRequestPage,
    element: <AdminPageLoanTabs />,
    index: true,
  },
  {
    path: ROUTES_LOANS.Admin.AuditPage,
    element: <AdminPageLoanTabs />,
    index: true,
  },
  {
    path: ORGANIZATION_ROUTE,
    element: <AdminOrganizations />,
  },
  {
    path: ORGANIZATION_DETAILS_ROUTE,
    element: <LpOrganizationDetails />,
  },
  {
    path: ACCOUNT_ROUTE,
    element: <Account />,
  },
  {
    path: ADMIN_DOCUMENT_APPROVAL_ROUTE,
    element: <DocumentApproval />,
  },
  {
    path: LIQUIDITY_DELIVERY_ROUTE,
    element: <LiquidityDeliveryPage />,
  },
  { path: RoutesHashRatePrediction.Default, element: <HashrateModel /> },
  { path: ROUTE_ADMIN_STREAMING_PHYSICAL, element: <div>TODO: Streaming physical</div> },
  { path: ROUTE_ADMIN_MINING_PROXY, element: <AdminMiningProxyPage /> },
  ...ADMIN_TABS_TOOLING.map((entry) => ({ path: entry.path, element: <AdminToolingTabs /> })),
  ...KYM2_MINER_DETAILS_TABS.map((entry) => ({ path: entry.path, element: <MinerDetailTabs /> })),
  ...KYM2_ADMIN_OPPORTUNITY_TABS.map((entry) => ({ path: entry.path, element: <AdminOpportunityTabs /> })),
  ...ADMIN_PHYSICAL_OPPORTUNITY_TABS.map((entry) => ({ path: entry.path, element: <AdminPhysicalOpportunityTabs /> })),
];

const getUserSpecificRoutes = (user: AuthUserResponseDto | null | undefined): RouteObject[] => {
  if (user) {
    const { userType } = user;

    switch (userType) {
      case AuthUserResponseDtoUserTypeEnum.Miner:
        return MINER_ROUTES;
      case AuthUserResponseDtoUserTypeEnum.LP:
        return LP_ROUTES;
      case AuthUserResponseDtoUserTypeEnum.Admin:
        return ADMIN_ROUTES;
      case undefined:
        return [];
    }
  } else return [];
};

export const router = (user: AuthUserResponseDto | null | undefined) => {
  return createBrowserRouter([
    {
      path: HOME_ROUTE,
      element: <ProtectedRoute>{selectLayout(user)}</ProtectedRoute>,
      errorElement: (
        <MainLayout isErrorPage>
          <ErrorPage title="Oops" status="404" message="This page does not exist" />
        </MainLayout>
      ),
      children: getUserSpecificRoutes(user),
    },
    {
      path: LOGOUT,
      element: <Logout />,
    },
    {
      path: ONNBOARDING_ROUTE,
      element: (
        <OnboardingLayout>
          <Onboarding />
        </OnboardingLayout>
      ),
    },
    {
      path: ENTRYFORM_LP_ROUTE,
      element: <Navigate to={ONNBOARDING_ROUTE + "?role=lp"} />,
    },
    {
      path: ENTRYFORM_MINER_ROUTE,
      element: <Navigate to={ONNBOARDING_ROUTE + "?role=miner"} />,
    },
    {
      path: THANK_YOU_ROUTE,
      element: <ThankYou />,
    },
    {
      path: DISCLAIMER_ROUTE,
      element: (
        <DisclaimerLayout>
          <Disclaimer />
        </DisclaimerLayout>
      ),
    },
  ]);
};
