import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";

const getMinerOpportunityHistoryTxs = async (
  id: string,
  query: Parameters<typeof apig.miner.getMinerOpportunityHistoryTxs>[1]
) => {
  return (await apig.miner.getMinerOpportunityHistoryTxs(id, query)).data;
};

const getCollateralInfo = async (id: string) => {
  return (await apig.opportunity.getCollateral(id)).data;
};

const getAdminOpportunitySummary = async (id: string) => {
  return (await apig.admin.getAdminOpportunitySummary(id)).data;
};

const getOpportunityProgress = async (id: string) => {
  return (await apig.opportunity.getOpportunityProgress(id)).data;
};

const useGetQueryMinerOpportunityHistory = (
  id: string,
  query: Parameters<typeof apig.miner.getMinerOpportunityHistoryTxs>[1]
) => {
  return useQuery(["opportunity_transaction_history", id, query], () => getMinerOpportunityHistoryTxs(id, query), {
    refetchOnMount: "always",
  });
};

const useGetQueryCollateralInfo = (id: string) => {
  return useQuery(["collateral_info", id], () => getCollateralInfo(id), {
    refetchOnMount: "always",
  });
};

const useGetQueryAdminOpportunitySummary = (id: string) => {
  return useQuery([`opportunity_${id}_summary`], () => getAdminOpportunitySummary(id), {
    refetchOnMount: "always",
  });
};

const useGetQueryOpportunityProgress = (id: string) => {
  return useQuery(["opportunity_progress", id], () => getOpportunityProgress(id), {
    refetchOnMount: "always",
  });
};

export {
  useGetQueryAdminOpportunitySummary,
  useGetQueryCollateralInfo,
  useGetQueryMinerOpportunityHistory,
  useGetQueryOpportunityProgress,
};
