import React from "react";
import { Typography, Chip, Skeleton, CircularProgress } from "@mui/material";
import { useGetMiningProxyGroupHashrate, useGetMiningProxyGroupStatus } from "../../../../app/query/useQueryGetAdmin";

export const GroupHashrate: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { data: hashrateData, error, isLoading } = useGetMiningProxyGroupHashrate(uuid);

  if (isLoading) {
    return <Skeleton width={150} />;
  }

  if (error) {
    return (
      <Typography variant="body2" color="error">
        Error loading hashrate
      </Typography>
    );
  }

  return (
    <Typography variant="body2" color="textSecondary">
      {hashrateData?.hashrate || 0} H/s
    </Typography>
  );
};

export const GroupStatus: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { data: statusData, error, isLoading } = useGetMiningProxyGroupStatus(uuid);

  if (isLoading) {
    return <Chip size="small" icon={<CircularProgress size={16} />} sx={{ ml: 1 }} />;
  }

  if (error) {
    return <Chip size="small" label="Error" color="error" sx={{ ml: 1 }} />;
  }

  return (
    <Chip
      size="small"
      label={statusData?.status || "Unknown"}
      color={statusData?.status === "online" ? "success" : "error"}
      sx={{ ml: 1 }}
    />
  );
};
