import { InProgressSteps, StageType, STAGE_STEPS, AllSteps, isFinalStep } from "./types";

export const STAGE_LABELS = {
  SETUP: "Setup & Funding",
  ACTIVE: "Active",
  SETTLEMENT: "Settlement",
} as const;

const STAGE_ORDER: StageType[] = ["SETUP", "ACTIVE", "SETTLEMENT"];

// Helper functions to determine stage and step status
export const getStageFromStep = (step: InProgressSteps): StageType => {
  for (const [stage, steps] of Object.entries(STAGE_STEPS)) {
    if (steps.includes(step)) {
      return stage as StageType;
    }
  }
  throw new Error(`Invalid step: ${step}`);
};

export const isStepCompleted = (step: InProgressSteps, currentStep: AllSteps): boolean => {
  if (isFinalStep(currentStep)) return true;
  const currentStage = getStageFromStep(currentStep);
  const stepStage = getStageFromStep(step);
  const currentStageIndex = STAGE_ORDER.indexOf(currentStage);
  const stepStageIndex = STAGE_ORDER.indexOf(stepStage);

  if (stepStageIndex !== currentStageIndex) {
    return stepStageIndex < currentStageIndex;
  }

  const stageSteps = STAGE_STEPS[currentStage];
  return stageSteps.indexOf(step) < stageSteps.indexOf(currentStep);
};
