import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { MouseEvent, useState } from "react";
import { ContractDto, GroupDto } from "../../../app/model/api";
import { useMutationUpdateGroupDefaultPool } from "../../../app/query/useMutationAdmin";
import {
  useGetMiningProxyContracts,
  useGetMiningProxyGroups,
  useGetMiningProxyGroupHashrate,
} from "../../../app/query/useQueryGetAdmin";
import { GroupHashrate, GroupStatus } from "./components/GroupComponents";

const formatPoolUrl = (url: string, user?: string, password?: string): string => {
  if (!user && !password) return url;
  const credentials = `${user || ""}${password ? ":" + password : ""}`;
  return `${credentials}@${url}`;
};

interface EditPoolModalProps {
  open: boolean;
  onClose: () => void;
  group: GroupDto;
  onSave: (defaultPool: string) => void;
}

const LabelValue = ({ label, value }: { label: string; value: React.ReactNode }) => (
  <Box display="flex" alignItems="center" gap={1}>
    <Typography variant="body2" color="textSecondary" sx={{ minWidth: 120 }}>
      {label}:
    </Typography>
    <Typography variant="body2" color="textSecondary" sx={{ flex: 1 }}>
      {value}
    </Typography>
  </Box>
);

const EditPoolModal = ({ open, onClose, group, onSave }: EditPoolModalProps) => {
  const [defaultPool, setDefaultPool] = useState(group.defaultPool);
  const { data: hashrateData } = useGetMiningProxyGroupHashrate(group.uuid);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Default Pool</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          <LabelValue label="Group" value={group.name} />
          <LabelValue label="Hashrate" value={`${hashrateData?.hashrate || 0} H/s`} />
          <LabelValue label="Proxy Address" value={`${group.endpoint}:${group.port}`} />
          <Box mt={2}>
            <TextField
              label="New Default Pool"
              value={defaultPool}
              onChange={(e) => setDefaultPool(e.target.value)}
              fullWidth
              autoFocus
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(defaultPool)} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AdminMiningProxyPage = () => {
  const { data: groups, isLoading: isLoadingGroups, error: groupsError } = useGetMiningProxyGroups();
  const { data: contractsByGroup, isLoading: isLoadingContracts, error: contractsError } = useGetMiningProxyContracts();
  const updateDefaultPool = useMutationUpdateGroupDefaultPool();
  const [editingGroup, setEditingGroup] = useState<GroupDto | null>(null);

  if (isLoadingGroups || isLoadingContracts) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (groupsError || contractsError) {
    return (
      <Box p={2}>
        <Typography color="error">Error loading mining proxy data</Typography>
      </Box>
    );
  }

  const handleEditClick = (e: MouseEvent, group: GroupDto) => {
    e.stopPropagation();
    setEditingGroup(group);
  };

  const handleSaveDefaultPool = async (defaultPool: string) => {
    if (!editingGroup) return;

    await updateDefaultPool.mutateAsync({
      uuid: editingGroup.uuid,
      defaultPool,
    });
    setEditingGroup(null);
  };

  const renderGroup = (group: GroupDto) => {
    const groupContracts = contractsByGroup?.contracts[group.uuid] || [];

    return (
      <TreeItem
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          pt: 1,
          pb: 1,
        }}
        key={group.uuid}
        itemId={group.uuid}
        label={
          <Box>
            <Box display="flex" alignItems="center" gap={1} mb={0.5}>
              <Typography variant="subtitle2">{group.name}</Typography>
              <Chip
                icon={<FolderIcon />}
                label="Group"
                size="small"
                color="default"
                variant="outlined"
                sx={{ height: 24, pl: 0.5 }}
              />
              <GroupStatus uuid={group.uuid} />
            </Box>
            <Box display="flex" flexDirection="column">
              <LabelValue label="Proxy Address" value={`${group.endpoint}:${group.port}`} />
              <LabelValue
                label="Default Pool"
                value={
                  <>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" color="textSecondary">
                        {group.defaultPool}
                      </Typography>
                      <IconButton size="small" onClick={(e) => handleEditClick(e, group)} sx={{ ml: 0.5 }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </>
                }
              />
              <LabelValue label="Hashrate" value={<GroupHashrate uuid={group.uuid} />} />
            </Box>
          </Box>
        }
      >
        {groupContracts.map((contract: ContractDto) => (
          <TreeItem
            key={contract.uuid}
            itemId={`contract-${contract.uuid}`}
            sx={{
              "& .MuiTreeItem-content": {
                borderRadius: 1,
                backgroundColor: "rgba(25, 118, 210, 0.08)",
                margin: "4px 0",
                padding: 1,
                border: "1px solid rgba(25, 118, 210, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.12)",
                },
              },
            }}
            label={
              <Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Chip
                    icon={<DescriptionIcon />}
                    label="Contract"
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: 24, pl: 0.5, mb: 0.5 }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <LabelValue
                    label="Pool"
                    value={formatPoolUrl(contract.miningPoolURL, contract.miningPoolUser, contract.miningPoolPass)}
                  />
                  <LabelValue label="Hashrate" value={`${contract.assignedHashrate} H/s`} />
                  <LabelValue label="Worker ID Mode" value={contract.workernameOption} />
                </Box>
              </Box>
            }
          />
        ))}
        {group.members?.map((subGroup) => renderGroup(subGroup))}
      </TreeItem>
    );
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Mining Proxy Admin
      </Typography>
      <SimpleTreeView>{groups?.map((group) => renderGroup(group))}</SimpleTreeView>
      {editingGroup && (
        <EditPoolModal
          open={true}
          onClose={() => setEditingGroup(null)}
          group={editingGroup}
          onSave={handleSaveDefaultPool}
        />
      )}
    </Box>
  );
};
