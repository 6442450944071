import { useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useMutationBackfillAccounting } from "../../../app/query/useMutationAdmin";
import {
  BackfillAccountingDtoSortByEnum as SortBy,
  BackfillAccountingDtoSortDirectionEnum as SortDirection,
} from "../../../app/model/api";

interface BackfillAccountingResponse {
  processedTransactions: number;
  updatedTransactions: number;
  nextBatchPrevId?: string;
}

export default function BackfillAccountingPage() {
  const [limit, setLimit] = useState<number>(25);
  const [prevId, setPrevId] = useState<string>("");
  const [dateGte, setDateGte] = useState<Date | null>(null);
  const [dateLte, setDateLte] = useState<Date | null>(null);
  const [sortBy, setSortBy] = useState<SortBy | "">("");
  const [sortDirection, setSortDirection] = useState<SortDirection | "">("");

  const { mutate: backfillAccounting, isLoading, error, data: response } = useMutationBackfillAccounting();

  const handleSubmit = () => {
    const params = {
      limit,
      prevId: prevId || undefined,
      dateGte: dateGte?.toISOString(),
      dateLte: dateLte?.toISOString(),
      sortBy: sortBy || undefined,
      sortDirection: sortDirection || undefined,
    };
    backfillAccounting(params);
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Backfill Accounting Details
      </Typography>

      <Card sx={{ p: 3, mb: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <TextField
              label="Limit"
              type="number"
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              inputProps={{ min: 1, max: 500 }}
            />

            <TextField label="Previous ID" value={prevId} onChange={(e) => setPrevId(e.target.value)} />

            <DateTimePicker label="Date Greater Than or Equal" value={dateGte} onChange={setDateGte} />

            <DateTimePicker label="Date Less Than or Equal" value={dateLte} onChange={setDateLte} />

            <FormControl>
              <InputLabel>Sort By</InputLabel>
              <Select value={sortBy} label="Sort By" onChange={(e) => setSortBy(e.target.value as SortBy)}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(SortBy).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel>Sort Direction</InputLabel>
              <Select
                value={sortDirection}
                label="Sort Direction"
                onChange={(e) => setSortDirection(e.target.value as SortDirection)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(SortDirection).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? "Loading..." : "Backfill"}
            </Button>
          </Stack>
        </LocalizationProvider>
      </Card>

      {error instanceof Error && (
        <Card sx={{ p: 3, mb: 3, bgcolor: "error.main" }}>
          <Typography color="white">{error.message}</Typography>
        </Card>
      )}

      {response && (
        <Card sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Response
          </Typography>
          <Stack spacing={1}>
            <Typography>
              Processed Transactions: {(response as BackfillAccountingResponse).processedTransactions}
            </Typography>
            <Typography>
              Updated Transactions: {(response as BackfillAccountingResponse).updatedTransactions}
            </Typography>
            <Typography>
              Next Batch Previous ID: {(response as BackfillAccountingResponse).nextBatchPrevId || <em>None</em>}
            </Typography>
          </Stack>
        </Card>
      )}
    </Box>
  );
}
