interface ProgressBarProps {
  current: number;
  dataList: string[];
  className?: string;
}

import "./style.scss";

const ProgressBar = ({ current, dataList, className }: ProgressBarProps) => {
  const calculateCompletedWidth = (currentStep: number, totalSteps: number): number => {
    if (currentStep <= 1) return 0;
    if (currentStep > totalSteps) return 100;

    return ((currentStep - 1) / (totalSteps - 1)) * 100;
  };

  const completedWidth = calculateCompletedWidth(current, dataList.length);

  return (
    <div className="relative">
      <div className="basic-baseline" />
      <div className="completed-baseline" style={{ width: `${completedWidth}%` }} />
      <div className={`flex justify-between ${className || ""}`}>
        {dataList.map((item, index) => {
          const stepNumber = index + 1;
          const isCompleted = stepNumber <= current;

          return (
            <div
              key={`${item}-${stepNumber}`}
              className={`step flex flex-col items-center ${isCompleted ? "completed" : ""}`}
            >
              <div className={`step-point ${isCompleted ? "completed" : ""}`} />
              <div className="step-name">{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
