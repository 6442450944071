import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Grid, IconButton, Paper, Skeleton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useState } from "react";
import { OpportunityProgressDto, OpportunityProgressDtoCurrentStageEnum } from "../../../app/model/api";
import { useGetQueryOpportunityProgress } from "../../../app/query/useGetQueryOpportunity";
import { StageContent } from "./components/StageContent";
import { getStageFromStep, STAGE_LABELS } from "./constants";
import {
  AllSteps,
  InProgressSteps,
  isActiveStep,
  isFinalStep,
  OpportunityStatusProgressProps,
  StageType,
} from "./types";

const STEPPER_SX = {
  "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line, & .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
    {
      borderColor: "primary.main",
    },
  "& .MuiStepConnector-line": {
    borderColor: "divider",
  },
} as const;

const getActiveStepIndex = (currentStage: AllSteps, stages: Array<{ type: StageType }>) => {
  // todo: handle each one individually
  if (isFinalStep(currentStage)) return -1;
  return stages.findIndex((s) => s.type === getStageFromStep(currentStage as InProgressSteps));
};

export const OpportunityStatusProgress = ({ opportunityId, className }: OpportunityStatusProgressProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: progress, isLoading } = useGetQueryOpportunityProgress(opportunityId);

  const stages: Array<{ type: StageType }> = [{ type: "SETUP" }, { type: "ACTIVE" }, { type: "SETTLEMENT" }];

  const getActiveStageError = (progress: OpportunityProgressDto) => {
    const isUnderCollateralized = progress.collateralStatus === "Under sufficient";
    const lastRewardDate = progress.lastRewardAt ? new Date(progress.lastRewardAt) : null;
    const HOURS_UNTIL_OVERDUE = 26;
    const hoursSinceLastReward = lastRewardDate
      ? Math.floor((Date.now() - lastRewardDate.getTime()) / (1000 * 60 * 60))
      : 0;
    const isRewardsOverdue = hoursSinceLastReward > HOURS_UNTIL_OVERDUE;

    if (isRewardsOverdue) {
      return "Mining rewards overdue";
    }
    if (isUnderCollateralized) {
      return "Collateral balance is too low";
    }
    return null;
  };

  const getStepError = (stage: StageType) => {
    if (!progress || isFinalStep(progress.currentStage)) return null;

    if (stage !== getStageFromStep(progress.currentStage)) return null;

    if (stage === "ACTIVE" && isActiveStep(progress.currentStage)) {
      return getActiveStageError(progress);
    }
    return null;
  };

  if (isLoading || !progress) {
    return <Skeleton width="100%" height={100}></Skeleton>;
  }

  return (
    <Box className={className}>
      <Paper elevation={0} sx={{ p: 3, bgcolor: "background.default" }}>
        <Box display="flex" gap={2} alignItems="flex-start">
          <Box flex={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <Stepper
                  activeStep={getActiveStepIndex(progress.currentStage, stages)}
                  orientation="horizontal"
                  sx={STEPPER_SX}
                >
                  {stages.map((stage) => {
                    const stepError = getStepError(stage.type);
                    const labelProps: {
                      optional?: React.ReactNode;
                      error?: boolean;
                    } = {};

                    if (stepError) {
                      labelProps.optional = (
                        <Typography variant="caption" color="error">
                          {stepError}
                        </Typography>
                      );
                      labelProps.error = true;
                    }

                    return (
                      <Step
                        key={stage.type}
                        completed={
                          progress.currentStage === OpportunityProgressDtoCurrentStageEnum.DONE ||
                          getActiveStepIndex(progress.currentStage, stages) >
                            stages.findIndex((s) => s.type === stage.type)
                        }
                      >
                        <StepLabel {...labelProps}>
                          <Typography variant="body2" fontWeight="medium">
                            {STAGE_LABELS[stage.type]}
                          </Typography>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              <IconButton onClick={() => setIsExpanded(!isExpanded)} size="small" sx={{ ml: 1 }}>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>

            <Collapse in={isExpanded}>
              <Box mt={2}>
                <Grid
                  container
                  spacing={20}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <StageContent type="SETUP" data={progress} />
                  <StageContent type="ACTIVE" data={progress} />
                  <StageContent type="SETTLEMENT" data={progress} />
                </Grid>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
