/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface TransferRequestDto {
  amount: string;
  receiverAddress: string;
  fundingId?: string;
}

export interface TransactionSendDto {
  txId: string;
  url: string;
}

export interface FeeDto {
  fee: number;
}

export interface ResTransactionDto {
  id: string;
  idNum?: number;
  opportunityId?: string;
  datetime?: string;
  fromAddr: string;
  toAddr: string;
  amountBtc: number;
  amountUsd: number;
  status: ResTransactionDtoStatusEnum;
  txHash: string;
  txLink: string;
  transactionType: ResTransactionDtoTransactionTypeEnum;
  fromName?: string;
  toName?: string;
}

export interface OverviewHistoryTransactionsDto {
  count: number;
  rows: ResTransactionDto[];
}

export interface WithdrawRequestDto {
  address: string;
  amount: number;
  note?: string;
}

export interface WithdrawResponseDto {
  from?: string;
  to?: string;
  datetime?: string;
  receivingAmountBtc?: number;
  receivingAmountUsd?: number;
  transactionFeeBtc?: number;
  transactionFeeUsd?: number;
  totalWithdrawalBtc?: number;
  totalWithdrawalUsd?: number;
  status?: WithdrawResponseDtoStatusEnum;
  note?: string;
  txHash?: string;
  url?: string;
}

export interface GetDepositTxsResponseDto {
  rows: ResTransactionDto[];
  count: number;
}

export interface BackfillAccountingDto {
  /**
   * @min 1
   * @max 500
   */
  limit?: number;
  prevId?: string;
  dateGte?: string;
  dateLte?: string;
  sortBy?: BackfillAccountingDtoSortByEnum;
  sortDirection?: BackfillAccountingDtoSortDirectionEnum;
}

export interface BackfillAccountingResponseDto {
  processedTransactions: number;
  updatedTransactions: number;
  nextBatchPrevId?: string | null;
}

export interface NotificationResponseDto {
  id: string;
  title: string;
  description: string;
  priority: NotificationResponseDtoPriorityEnum;
  actionable: NotificationResponseDtoActionableEnum;
  link: string | null;
  /** @format date-time */
  readAt: string | null;
  /** @format date-time */
  createdAt: string;
}

export interface MarkAsReadDto {
  notifications: string[];
}

export interface InitiateOpportunityFunding {
  amountSatoshis: string;
  opportunityId: string;
}

export interface OpportunityFundingResponseDto {
  id: string;
  approvalStatus: OpportunityFundingResponseDtoApprovalStatusEnum;
  synhashPoolAddress?: string;
  amountSatoshis: string;
  /** @format date-time */
  createdAt: string;
  opportunityId: string;
}

export interface LpAgreementOpportunityDto {
  id: string;
  opportunityId: string;
  opportunityNumId: number;
  approvalStatus: LpAgreementOpportunityDtoApprovalStatusEnum;
  companyName: string;
  amountSatoshis: number;
}

export interface LpAgreementOpportunitiesPageDto {
  count: number;
  rows: LpAgreementOpportunityDto[];
}

export interface UpdateFundingApprovalStatusDto {
  status: UpdateFundingApprovalStatusDtoStatusEnum;
}

export interface BasicLiquidityProviderDto {
  id: string;
  /** @format date-time */
  fundedAt?: string;
  companyName: string;
  amountSatoshi: number;
}

export interface ResBasicLiquidityProviders {
  count: number;
  rows: BasicLiquidityProviderDto[];
}

export interface UpdateOpportunityFunding {
  id: string;
  visibleToOthers: boolean;
}

export interface CollateralInfoDto {
  currentBalanceSatoshis: number;
  collateralRequirementSatoshis: number;
  missingCollateralSatoshis: number;
  liquidityDeliveryRequirement: {
    collateralRequirementSatoshis: number;
    missingCollateralSatoshis: number;
    additionalHashrateRequiredThPerSecond: number;
    totalHashrateRequiredThPerSecond: number;
  };
}

export interface OpportunityProgressDto {
  currentStage: OpportunityProgressDtoCurrentStageEnum;
  collateralStatus?: OpportunityProgressDtoCollateralStatusEnum;
  /** @format date-time */
  lastRewardAt?: string | null;
}

export interface AuthUserResponseDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  companyRole?: string;
  homepageUrl?: string | null;
  location?: string;
  userType: AuthUserResponseDtoUserTypeEnum;
  onboardingStatus?: AuthUserResponseDtoOnboardingStatusEnum;
  onboardingStatusAdminPanel?: AuthUserResponseDtoOnboardingStatusAdminPanelEnum;
  addressBtc?: string;
  walletAddress?: string;
  balanceSatoshis?: number;
  balanceUsd?: number;
  reservedBalanceSatoshis?: number;
  reservedBalanceUsd?: number;
  maximumSpendableSatoshis?: number;
  bitgoWalletUrl?: string;
  roles?: AuthUserResponseDtoRolesEnum[];
  requiredWhitelistApprovers: number;
  requiredAccessApprovers: number;
  permissions?: string[];
}

export interface MinerBasicDto {
  id: string;
  company: string;
}

export interface MinerListDto {
  miners: MinerBasicDto[];
}

export interface WithdrawalAddressDto {
  id?: string;
  address: string;
  name: string;
}

export interface RejectOrAcceptWhitelistProposalDto {
  proposalId: string;
  actionType: RejectOrAcceptWhitelistProposalDtoActionTypeEnum;
}

export interface UserBasicDto {
  id: string;
  firstName: string;
  lastName: string;
}

export interface WhitelistAddressApprovalDto {
  id: string;
  user: UserBasicDto;
  verdict: WhitelistAddressApprovalDtoVerdictEnum;
}

export interface WhitelistAddressProposalDto {
  id: string;
  address: string;
  name: string;
  approvalStatus: WhitelistAddressProposalDtoApprovalStatusEnum;
  approval: WhitelistAddressApprovalDto[];
  proposerUser: UserBasicDto;
}

export interface GetWhitelistProposalResponseDto {
  count: number;
  rows: WhitelistAddressProposalDto[];
}

export interface GetWhitelistedAddressesResponseDto {
  count: number;
  rows: WithdrawalAddressDto[];
}

export interface OrganizationUserDto {
  id: string;
  firstName: string;
  lastName: string;
  roles: OrganizationUserDtoRolesEnum[];
}

export interface GetOrganizationUsersResponseDto {
  count: number;
  rows: OrganizationUserDto[];
}

export interface ManagerCountDto {
  accessManagerCount: number;
  walletManagerCount: number;
  opportunityManagerCount: number;
}

export interface InviteOrganizationUserDto {
  email: string;
  firstName: string;
  lastName: string;
  isAccessManager?: boolean;
  isWalletManager?: boolean;
  isOpportunityManager?: boolean;
}

export interface AccessApprovalDto {
  id: string;
  user: UserBasicDto;
  verdict: AccessApprovalDtoVerdictEnum;
}

export interface AccessProposalDto {
  id: string;
  user: UserBasicDto;
  proposer: UserBasicDto;
  role: string;
  approvalStatus: string;
  accessApproval: AccessApprovalDto[];
}

export interface GetManagerProposalsResponseDto {
  count: number;
  rows: AccessProposalDto[];
}

export interface CreateProposalDto {
  role: CreateProposalDtoRoleEnum;
  userId: string;
}

export interface RejectOrAcceptProposalDto {
  accessProposalId: string;
  actionType: RejectOrAcceptProposalDtoActionTypeEnum;
}

export interface ResLoanPayment {
  /** @format date-time */
  date: string;
  expectedFromMinerSatoshis: number;
  forLPSatoshis: number;
  totalExpectedFromMinerSoFar: number;
  totalForLPSoFar: number;
}

export interface ResLoanMinerDelivery {
  id: string;
  /** @format date-time */
  createdAt: string;
  fromAddresses: string[];
  status: string;
  transactionHash: string;
  amountSatoshi: number;
}

export interface ResLoanPaymentRequestDto {
  id: string;
  amountSatoshi: number;
  toAddress: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  executeAfter: string;
  /** @format date-time */
  executedAt?: string;
  isFailed: boolean;
  /** @format date-time */
  confirmedAt?: string;
  transactionHash?: string;
  destination: ResLoanPaymentRequestDtoDestinationEnum;
}

export interface ResLoanAuditTransactionDto {
  id: string;
  amountSatoshi: number;
  toAddresses: string;
  fromAddresses: string;
  /** @format date-time */
  createdAt: string;
  transactionHash?: string;
}

export interface ResLoanAssociatedValidAddress {
  address: string;
  bitgoWalletId?: string;
  name: string;
  addressType: ResLoanAssociatedValidAddressAddressTypeEnum;
}

export interface ResBalanceInfoSatoshi {
  totalDeliveries: number;
  confirmedDeliveries: number;
  totalPaymentsToLp: number;
  confirmedPaymentsToLp: number;
  totalPaymentsToMiner: number;
  confirmedPaymentsToMiner: number;
  totalPaymentsToOther: number;
  confirmedPaymentsToOther: number;
  minAvailableBalance: number;
  recommendedMinerPayment: number;
  recommendedLpPayment: number;
}

export interface ReqLoanPayment {
  date: string;
  expectedFromMinerSatoshis: number;
  forLPSatoshis: number;
}

export interface ResAdminLoanDto {
  id: string;
  idNum: number;
  minerId: string;
  isDemo: boolean;
  minerName: string;
  principalAmountSatoshi: number;
  durationDays: number;
  daysPerYear: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  cancelledAt?: string;
  /** @format date-time */
  completedAt?: string;
  rewardAddress?: string;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  expectedEndDate?: string;
  remainingDuration?: string;
  status: ResAdminLoanDtoStatusEnum;
  expectedPayments: ResLoanPayment[];
  minerDeliveries: ResLoanMinerDelivery[];
  isSigned: boolean;
  lpId: string;
  lpName: string;
  rewardBitgoWalletId?: string;
  outgoingPayments: ResLoanPaymentRequestDto[];
  auditTransactions: ResLoanAuditTransactionDto[];
  addresses: ResLoanAssociatedValidAddress[];
  balanceInfoSatoshi: ResBalanceInfoSatoshi;
  calculationKind: ResAdminLoanDtoCalculationKindEnum;
  minerAnnualInterestRatePercent: number;
  lpAnnualInterestRatePercent: number;
  customSchedule?: ReqLoanPayment[];
}

export interface ResAdminLoans {
  rows: ResAdminLoanDto[];
}

export interface ReqCreateDraftLoanDto {
  minerId: string;
  lpId: string;
  durationDays: number;
}

export interface IdDto {
  id: string;
}

export interface ReqGenerateDemo {
  loanId: string;
  deliveriesCount: number;
  scenario: ReqGenerateDemoScenarioEnum;
}

export interface ResGenerateDemo {
  status: ResGenerateDemoStatusEnum;
  count: number;
}

export interface ResLoanBitgoBalance {
  rewardBitgoWalletId?: string;
  address?: string;
  balance: number;
  confirmedBalanceSatoshi: number;
  spendableBalanceSatoshi: number;
}

export interface ReqUpdateDraftLoan {
  id: string;
  isDemo: boolean;
  durationDays: number;
  daysPerYear?: number;
  principalAmountSatoshi: number;
  startDate: string;
  isSigned: boolean;
  calculationKind: ReqUpdateDraftLoanCalculationKindEnum;
  minerAnnualInterestRatePercent: number;
  lpAnnualInterestRatePercent: number;
  customSchedule?: ReqLoanPayment[];
}

export interface ReqCreatePaymentRequest {
  loanId: string;
  amountSatoshi: number;
  address: string;
  destination: ReqCreatePaymentRequestDestinationEnum;
  executionDelaySeconds: number;
}

export interface ResCreatePaymentRequest {
  kind: string;
}

export interface ResDeletePaymentRequest {
  status: ResDeletePaymentRequestStatusEnum;
}

export interface ResMinerLoanDto {
  id: string;
  idNum: number;
  minerId: string;
  isDemo: boolean;
  minerName: string;
  principalAmountSatoshi: number;
  durationDays: number;
  daysPerYear: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  cancelledAt?: string;
  /** @format date-time */
  completedAt?: string;
  rewardAddress?: string;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  expectedEndDate?: string;
  remainingDuration?: string;
  status: ResMinerLoanDtoStatusEnum;
  expectedPayments: ResLoanPayment[];
  minerDeliveries: ResLoanMinerDelivery[];
  calculationKind: ResMinerLoanDtoCalculationKindEnum;
  minerAnnualInterestRatePercent: number;
  paymentsToMiner: ResLoanPaymentRequestDto[];
}

export interface ResMinerLoans {
  rows: ResMinerLoanDto[];
}

export interface ResLpLoanDto {
  id: string;
  idNum: number;
  minerId: string;
  isDemo: boolean;
  minerName: string;
  principalAmountSatoshi: number;
  durationDays: number;
  daysPerYear: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  cancelledAt?: string;
  /** @format date-time */
  completedAt?: string;
  rewardAddress?: string;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  expectedEndDate?: string;
  remainingDuration?: string;
  status: ResLpLoanDtoStatusEnum;
  expectedPayments: ResLoanPayment[];
  minerDeliveries: ResLoanMinerDelivery[];
  calculationKind: ResLpLoanDtoCalculationKindEnum;
  lpAnnualInterestRatePercent: number;
  paymentsToLp: ResLoanPaymentRequestDto[];
}

export interface ResLpLoans {
  rows: ResLpLoanDto[];
}

export enum YesOrNoChoice {
  Yes = "yes",
  No = "no",
}

export enum OpportunityStatus {
  AwaitingCollateral = "Awaiting Collateral",
  AwaitingFunding = "Awaiting Funding",
  Draft = "Draft",
  Active = "Active",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Settled = "Settled",
  Closed = "Closed",
}

export enum Sort {
  Asc = "asc",
  Desc = "desc",
}

export interface EnergyMixDTO {
  /**
   * @min 0
   * @max 100
   */
  Wind?: number;
  /**
   * @min 0
   * @max 100
   */
  Coal?: number;
  /**
   * @min 0
   * @max 100
   */
  Hydro?: number;
  /**
   * @min 0
   * @max 100
   */
  Nuclear?: number;
  /**
   * @min 0
   * @max 100
   */
  "Flared gas"?: number;
  /**
   * @min 0
   * @max 100
   */
  "Natural gas"?: number;
  /**
   * @min 0
   * @max 100
   */
  Oil?: number;
  /**
   * @min 0
   * @max 100
   */
  Other?: number;
}

export interface OpportunityBasicMiner {
  id: string;
  /** @format date-time */
  dateOfIncorporation?: string;
  company?: string;
  location?: string;
  capacityOverSites?: number;
  miningCapacityPh?: number;
  operatingMachines?: number;
  /** @format date-time */
  registeredDate?: string;
  homepageUrl?: string | null;
  totalSelfMiningCapacity?: number;
  averageHardwareEfficiency?: number;
  energyMix: EnergyMixDTO | null;
  financialHealthRate?: OpportunityBasicMinerFinancialHealthRateEnum;
  operationalHealthRate?: OpportunityBasicMinerOperationalHealthRateEnum;
  sustainabilityRate?: OpportunityBasicMinerSustainabilityRateEnum;
  totalRevenue: number | null;
}

export interface LiquidityReceived {
  current: number;
  total: number;
}

export interface StreamingBasic {
  id: string;
  status: StreamingBasicStatusEnum;
  idNum: number;
  miner: OpportunityBasicMiner;
  remainingDuration: string | null;
  fundingDeadline: string | null;
  currentHashrate: number;
  liquidityReceived: LiquidityReceived;
  /** @format date-time */
  activeSince?: string;
  /** @format date-time */
  settlementDate?: string;
  /** @format date-time */
  agreementEndDate: string;
  isDemo?: boolean;
}

export interface StreamingOpportunitiesDto {
  rows: StreamingBasic[];
  count: number;
}

export interface CollateralRequestDto {
  amount: string;
}

export interface OpportunitiesMinerSummaryDto {
  allocatedHashRate: number;
  currentCollateralLocked: {
    btc: number;
    usd: number;
  };
  allTimeLiquidityReceived: {
    btc: number;
    usd: number;
  };
}

export interface BtcUsdDto {
  btc: number;
  usd: number;
}

export interface HashrateAllocatedDto {
  maxCapacity: number;
  currentHashRate: number;
}

export interface GetMinerOpportunitySummaryResponseDto {
  liquidityReceived: BtcUsdDto;
  collateralBalance: BtcUsdDto;
  hashrateAllocated: HashrateAllocatedDto;
}

export interface ResBasicOnboardingMinerDetailsDto {
  organizationId: string;
  isPubliclyListed: boolean;
  installedHashratePhPerSecond: number;
  energyPriceUsdPerMwh: number;
}

export interface ReqCreatePhysicalOpportunityDto {
  minerId: string;
  lpId: string;
  /** @min 1 */
  durationDays: number;
  startDate: string;
  /** @min 1 */
  principalAmountSatoshi: number;
  /** @min 1 */
  fullHashrateThPerSecond: number;
  /** @min 0 */
  collateralPercentage: number;
  /** @min 0 */
  expectedReturnPercentage: number;
  /** @min 0 */
  structuringFeePercentage: number;
  /** @min 0 */
  managementFeePercentage: number;
}

export interface ReqUpdatePhysicalOpportunityDto {
  minerId: string;
  lpId: string;
  /** @min 1 */
  durationDays: number;
  startDate: string;
  /** @min 1 */
  principalAmountSatoshi: number;
  /** @min 1 */
  fullHashrateThPerSecond: number;
  /** @min 0 */
  collateralPercentage: number;
  /** @min 0 */
  expectedReturnPercentage: number;
  /** @min 0 */
  structuringFeePercentage: number;
  /** @min 0 */
  managementFeePercentage: number;
}

export interface ResPhysicalOpportunityDetailsDto {
  id: string;
  idNum: number;
  minerId: string;
  minerCompanyName: string;
  lpId: string;
  lpName: string;
  durationDays: number;
  /** @format date-time */
  startDate: string;
  principalAmountSatoshi: number;
  fullHashrateThPerSecond: number;
  /**
   * @min 0
   * @max 100
   */
  collateralPercentage: number;
  /**
   * @min 0
   * @max 100
   */
  expectedReturnPercentage: number;
  /**
   * @min 0
   * @max 100
   */
  structuringFeePercentage: number;
  /**
   * @min 0
   * @max 100
   */
  managementFeePercentage: number;
  status: ResPhysicalOpportunityDetailsDtoStatusEnum;
  collateralBitgoWalletId?: string;
  collateralAddress?: string;
  remainingDuration?: string;
  expectedEndDate?: string;
  closedAt?: string;
  cancelledAt?: string;
  defaultedAt?: string;
}

export interface ProtocolWalletDto {
  id: string;
  walletType: ProtocolWalletDtoWalletTypeEnum;
  walletAddress?: string;
}

export interface OpportunitiesDetails {
  id: string;
  durationOfAgreement: number;
  opportunityBasic: StreamingBasic;
  dataroomLink?: string;
  rewardAddress?: string;
  collateralAddress?: string;
  description: string;
  collateralStatus: OpportunitiesDetailsCollateralStatusEnum;
  collateralBalance?: number;
  /** @format date-time */
  fundingDeadline: string;
  partnership?: boolean;
  expectedReturn: number;
  maximumCapacity?: number;
  currentDeposits?: number;
  energyMix?: EnergyMixDTO | null;
  fullHashrateThPerSecond: number;
  requiredCollateralSatoshiPerOutstandingTh?: number | null;
  maxAmountOfLiquidity?: number;
  partnershipName?: string;
  protocolWallets: ProtocolWalletDto[];
  initialCollateral: number;
  historicalUptime: number | null;
  forecastRewards: number;
  structuringFeePercentage: number;
  managementFeePercentage: number;
  synHashPoolAddress: string;
  opportunityCapacity: number;
}

export type LoanBasic = object;

export type LeaseBasic = object;

export interface PreviewMinerBasic {
  id: string;
  name: string;
  homepageUrl?: string;
  /** @format date-time */
  dateOfIncorporation?: string;
  countryOfIncorporation?: string;
  miningCapacityPh?: number;
  energyMix?: EnergyMixDTO;
  financialHealthRating?: PreviewMinerBasicFinancialHealthRatingEnum;
  operationalHealthRating?: PreviewMinerBasicOperationalHealthRatingEnum;
  sustainabilityRating?: PreviewMinerBasicSustainabilityRatingEnum;
}

export interface PreviewStreamingOpportunityDataDto {
  id: string;
  miner: PreviewMinerBasic;
  description?: string;
  principalSatoshis?: number;
  principalDesc?: string;
  hashrateThPerSecond?: number;
  hashrateDesc?: string;
  /** @format date-time */
  startDate?: string;
  durationDays?: number;
  durationDesc?: string;
  forecastRewards?: number;
  managementFeePercentage?: number;
  collateralType?: string;
  lpInterestRate?: number;
}

export interface PreviewLoanDataDto {
  id: string;
  miner: PreviewMinerBasic;
  description?: string;
  principalSatoshis?: number;
  principalDesc?: string;
  /** @format date-time */
  startDate?: string;
  durationDays?: number;
  durationDesc?: string;
  totalReturn?: number;
  lpInterestRate?: number;
  collateralType?: string;
}

export interface PreviewLeaseDataDto {
  id: string;
  miner: PreviewMinerBasic;
  description?: string;
  principalSatoshis?: number;
  principalDesc?: string;
  /** @format date-time */
  startDate?: string;
  durationDays?: number;
  durationDesc?: string;
  totalReturn?: number;
  lpInterestRate?: number;
  collateralType?: string;
}

export interface LPOpportunitiesDto {
  streamingOpportunities: OpportunitiesDetails[];
  loanOpportunities: LoanBasic[];
  leaseOpportunities: LeaseBasic[];
  previewStreamingOpportunities: PreviewStreamingOpportunityDataDto[];
  previewLoanOpportunties: PreviewLoanDataDto[];
  previewLeaseOpportunities: PreviewLeaseDataDto[];
  count: number;
}

export interface OpportunityFullDataDto {
  opportunityDetails: OpportunitiesDetails;
}

export interface OpportunityFundedResponse {
  id: string;
  idNum: number;
  minerId: string;
  companyName: string;
  /** @format date-time */
  fullyFundedDate?: string;
  fundedHaspowerTh?: number | null;
  fullHashrateThPerSecond?: number | null;
  opportunityId: string;
  opportunityIdNum: number;
}

export interface OpportunityFundedResponseDto {
  count: number;
  rows: OpportunityFundedResponse[];
}

export interface ReqDataroomAccessDto {
  opportunityId: string;
}

export interface ResDataroomAccessDto {
  message: string;
  kind: string;
}

export interface PortfolioChartDataPoint {
  x: string;
  y: number;
}

export interface PortfolioChartLine {
  id: string;
  color: string;
  minerId: string;
  year: string;
  data: PortfolioChartDataPoint[];
}

export interface GetLpPortfolioChartResponseDto {
  data: PortfolioChartLine[];
}

export interface RevenueDto {
  btc: number;
  usd: number;
}

export interface GetPortfolioSummaryResponseDto {
  dailyRevenue: RevenueDto;
  overallRevenue: RevenueDto;
}

export interface GetNetworkStatsResponseDto {
  currentBtcPrice: number;
  totalNetworkHashrate: number;
}

export interface ContactDetailsDto {
  email: string;
  firstName: string;
  lastName: string;
  cellphone: string;
}

export interface PrimaryContactDto {
  primaryContactKind: PrimaryContactDtoPrimaryContactKindEnum;
  hasPowerOfAttorney: PrimaryContactDtoHasPowerOfAttorneyEnum;
  reference?: ContactDetailsDto;
}

export interface RegisterLpDto {
  user: ContactDetailsDto;
  companyName: string;
  companyRole: string;
  homepageUrl?: string;
  companyCountryOfIncorporation: string;
  isPubliclyListed: boolean;
  primaryContact: PrimaryContactDto;
  /** @min 0 */
  aumUsdMillions?: number;
  /** @min 0 */
  bitcoinAssetsUnderManagement?: number;
  /** @min 0 */
  projectedBitcoinInvestmentSize?: number;
  timeHorizonOfLiquidity: RegisterLpDtoTimeHorizonOfLiquidityEnum;
}

export interface ResOnboardingDto {
  id: string;
}

export interface RegisterMinerDto {
  user: ContactDetailsDto;
  companyName: string;
  companyRole: string;
  homepageUrl?: string;
  companyCountryOfIncorporation: string;
  isPubliclyListed: boolean;
  annualReportUrl?: string;
  primaryContact: PrimaryContactDto;
  primaryEnergySource?: string;
  /** @min 0 */
  miningCapacityPhPerSecond: number;
  /** @min 0 */
  averageEnergyPriceUsdPerMWh?: number;
  timeHorizonOfLiquidity: RegisterMinerDtoTimeHorizonOfLiquidityEnum;
}

export interface ResUploadedFileDto {
  id: string;
  filename: string;
}

export interface ResKymFinancialsDto {
  organizationId: string;
  balanceSheetFiles: ResUploadedFileDto[];
  cashFlowStatementFiles: ResUploadedFileDto[];
  incomeStatementFiles: ResUploadedFileDto[];
  auditEngagementLetterFiles: ResUploadedFileDto[];
  outstandingLiabilitiesFiles: ResUploadedFileDto[];
}

export interface FileIdDto {
  id: string;
}

export interface ReqNewFileDto {
  name: string;
  base64: string;
}

export interface ReqUpdateFilesDto {
  existingFiles: FileIdDto[];
  newFiles: ReqNewFileDto[];
}

export interface ReqUpdateKymFinancialsDto {
  balanceSheetFiles: ReqUpdateFilesDto;
  cashFlowStatementFiles: ReqUpdateFilesDto;
  incomeStatementFiles: ReqUpdateFilesDto;
  auditEngagementLetterFiles: ReqUpdateFilesDto;
  outstandingLiabilitiesFiles: ReqUpdateFilesDto;
}

export interface ResUpdateDto {
  kind: boolean;
}

export interface UpdateKymHostingDto {
  hostsOtherMiners: boolean;
  totalCurrentHostedCapacityPhPerS?: number;
  expectedCapacityIn12MonthsMPhPerS?: number;
  averageHostingPricePerClientUsdPerMWh?: number;
  priceHedgingStrategy?: string;
}

export interface KymAsicDto {
  /** @min 0 */
  averageFleetEfficiencyJPerTh: number;
  machineListFiles: ResUploadedFileDto[];
  /**
   * @min 0
   * @max 100
   */
  averageAsicPurchasePriceUsdPerTh?: number;
  machineInvoiceFiles: ResUploadedFileDto[];
  /**
   * @min 0
   * @max 100
   */
  asicUptimePercentage?: number;
  minerUptimeStatsFiles: ResUploadedFileDto[];
  /**
   * @min 0
   * @max 100
   */
  capacityEncumbrancePercentage: number;
  hasInsurance: boolean;
  insuranceDescription?: string;
}

export interface ResKymAsicDto {
  organizationId: string;
  payload?: KymAsicDto;
}

export interface ReqUpdateKymAsicDto {
  /** @min 0 */
  averageFleetEfficiencyJPerTh: number;
  machineListFiles: ReqUpdateFilesDto;
  /** @min 0 */
  averageAsicPurchasePriceUsdPerTh?: number;
  machineInvoiceFiles: ReqUpdateFilesDto;
  /**
   * @min 0
   * @max 100
   */
  asicUptimePercentage?: number;
  minerUptimeStatsFiles: ReqUpdateFilesDto;
  /**
   * @min 0
   * @max 100
   */
  capacityEncumbrancePercentage: number;
  hasInsurance: boolean;
  insuranceDescription?: string;
}

export interface EnergyElementDto {
  source: string;
  /**
   * @min 1
   * @max 100
   */
  percentage: number;
}

export interface KymEnergyProfileDto {
  energyElements: EnergyElementDto[];
  /**
   * @min 0
   * @max 100
   */
  renewableEnergyPercentage?: number;
  /** @min 0 */
  averageAllInEnergyPriceUsdPerMWh: number;
  hedgesEnergyPrice: boolean;
  hedgingDescription: string;
  hedgingSignedPpa?: ResUploadedFileDto[];
  curtailmentPrograms?: string;
  minimumPowerTakeOut?: string;
}

export interface ResKymEnergyProfileDto {
  organizationId: string;
  payload?: KymEnergyProfileDto;
}

export interface ReqKymEnergyProfileDto {
  energyElements: EnergyElementDto[];
  /**
   * @min 0
   * @max 100
   */
  renewableEnergyPercentage?: number;
  /** @min 0 */
  averageAllInEnergyPriceUsdPerMWh: number;
  hedgesEnergyPrice: boolean;
  hedgingDescription?: string;
  hedgingSignedPpa?: ReqUpdateFilesDto;
  curtailmentPrograms?: string;
  minimumPowerTakeOut?: string;
}

export interface ResKymCapacityMiningSite {
  id: string;
  location: string;
  /** @min 0 */
  hashrate: number;
}

export interface ResKymCapacityAndSitesDto {
  organizationId: string;
  /** @min 0 */
  currentInstalledHashrate?: number;
  /** @min 0 */
  estimatedHashrateIn12Months?: number;
  /** @min 0 */
  totalMiningSites?: number;
  miningSites: ResKymCapacityMiningSite[];
  hasThirdPartyProviderHostedMachines: boolean;
  /** @min 0 */
  thirdPartyInstalledHashratePhS?: number;
  /** @min 0 */
  averageHostingPriceUsdPerMwh?: number;
}

export interface ReqKymCapacityMiningSite {
  id?: string;
  location: string;
  /** @min 0 */
  hashrate: number;
}

export interface ReqUpdateKymCapacityAndSitesDto {
  /** @min 0 */
  currentInstalledHashrate: number;
  /** @min 0 */
  estimatedHashrateIn12Months?: number;
  /** @min 0 */
  totalMiningSites: number;
  miningSites: ReqKymCapacityMiningSite[];
  hasThirdPartyProviderHostedMachines: boolean;
  /** @min 0 */
  thirdPartyInstalledHashratePhS?: number;
  /** @min 0 */
  averageHostingPriceUsdPerMwh?: number;
}

export interface ResUpdateStatus {
  isUpdated: boolean;
}

export interface ReqByMinerIdDto {
  minerId: string;
}

export interface ResMinerKycApprovalDto {
  kind: ResMinerKycApprovalDtoKindEnum;
}

export interface OrganizationEntry {
  email: string;
  createdAt: string;
  id: string;
  company?: string;
  homepageUrl?: string | null;
  location?: string;
  userType: OrganizationEntryUserTypeEnum;
  onboardingStatus?: OrganizationEntryOnboardingStatusEnum;
  onboardingStatusAdminPanel?: OrganizationEntryOnboardingStatusAdminPanelEnum;
  numOfUsers?: number;
}

export interface OrganizationsResponseDto {
  rows: OrganizationEntry[];
  count: number;
}

export interface Managers {
  walletManagers: number;
  accessManagers: number;
}

export interface BasicInfoDto {
  managers: Managers;
  organizationId: string;
  isPubliclyListed: boolean | null;
  annualReport: string | null;
  onboardingUser: ContactDetailsDto;
  primaryContact?: PrimaryContactDto;
  id: string;
  company?: string;
  homepageUrl?: string | null;
  location?: string;
  userType: BasicInfoDtoUserTypeEnum;
  onboardingStatus?: BasicInfoDtoOnboardingStatusEnum;
  onboardingStatusAdminPanel?: BasicInfoDtoOnboardingStatusAdminPanelEnum;
  numOfUsers?: number;
}

export interface WalletDetailsDto {
  walletAddress: string;
  bitgoWalletId: string;
  bitgoWalletUrl: string;
}

export interface LpResponseDto {
  /** @min 0 */
  AUMMillionUSD?: number;
  portionOfBitcoinAssets?: number;
  bitcoinAum?: number;
  projectedAllocation?: LpResponseDtoProjectedAllocationEnum;
  projectedBitcoinInvestment: number;
  timeHorizonOfLiquidity?: LpResponseDtoTimeHorizonOfLiquidityEnum;
  liquidityHorizon: LpResponseDtoLiquidityHorizonEnum;
}

export interface LpOrganizationResponseDto {
  basicInfo: BasicInfoDto;
  walletDetails?: WalletDetailsDto;
  lpInfo: LpResponseDto;
}

export interface MinerResponseDto {
  averageAllInEnergyPrice: number;
  averageAllInEnergyPriceUnit: string;
  miningCapacity?: number;
  /** @min 0 */
  avgEnergyPrice?: number;
  energyCostsFixed?: boolean;
  isHosted?: boolean;
  hostingProvider?: string;
  /**
   * @min 0
   * @max 100
   */
  percentage?: number;
  primaryEnergySource?: string;
  isEncumbered?: boolean;
  percentEncumbrance?: MinerResponseDtoPercentEncumbranceEnum;
  liquidityAgreementDuration?: MinerResponseDtoLiquidityAgreementDurationEnum;
  liquidityHorizon: MinerResponseDtoLiquidityHorizonEnum;
  financialHealthRate?: MinerResponseDtoFinancialHealthRateEnum;
  operationalHealthRate?: MinerResponseDtoOperationalHealthRateEnum;
  sustainabilityRate?: MinerResponseDtoSustainabilityRateEnum;
}

export interface MinerInfoResponseDto {
  basicInfo: BasicInfoDto;
  walletDetails?: WalletDetailsDto;
  minerInfo: MinerResponseDto;
}

export interface ResMinerSummaryDto {
  minerId: string;
  onboardingStatus: ResMinerSummaryDtoOnboardingStatusEnum;
  companyName: string;
  asicUptimePercentage?: number;
  financialHealthRate?: ResMinerSummaryDtoFinancialHealthRateEnum;
  operationalHealthRate?: ResMinerSummaryDtoOperationalHealthRateEnum;
  sustainabilityRate?: ResMinerSummaryDtoSustainabilityRateEnum;
  energyElements: EnergyElementDto[];
}

export interface ResMinerDumpDto {
  json?: string;
}

export interface ResDownloadUrlDto {
  url?: string;
}

export interface ReqCreateOpportunityV2Dto {
  minerId: string;
  /** @format date-time */
  demoStartDate?: string;
  /** @min 0 */
  requiredHashPowerForCompletionPhPerS: number;
  /**
   * @min 1
   * @max 100
   */
  initialCollateralPercentage: number;
  maximumAmountOfLiquidityBtc: number;
  /** @format date-time */
  fundingDeadline: string;
  durationOfAgreementDays: number;
  /**
   * @min 0
   * @max 100
   */
  expectedReturnPercentage: number;
  /**
   * @min 0
   * @max 100
   */
  structuringFeePercentage: number;
  /**
   * @min 0
   * @max 100
   */
  managementFeePercentage: number;
}

export interface ResCreateOpportunityV2Dto {
  id: string;
}

export interface ReqUpdateOpportunityV2Dto {
  opportunityId: string;
  /** @min 0 */
  requiredHashPowerForCompletionPhPerS: number;
  /**
   * @min 1
   * @max 100
   */
  initialCollateralPercentage: number;
  maximumAmountOfLiquidityBtc: number;
  /** @format date-time */
  fundingDeadline: string;
  durationOfAgreementDays: number;
  /**
   * @min 0
   * @max 100
   */
  expectedReturnPercentage: number;
  /**
   * @min 0
   * @max 100
   */
  structuringFeePercentage: number;
  /**
   * @min 0
   * @max 100
   */
  managementFeePercentage: number;
}

export interface ResOpportunityWalletInfoDto {
  /** @min 0 */
  walletBalance: number;
  collateralAddress?: string;
  rewardAddress?: string;
  synHashPoolAddress?: string;
}

export interface ResOpportunityDetailsV2Dto {
  minerId: string;
  minerCompanyName: string;
  opportunity: ReqUpdateOpportunityV2Dto;
  /** @format date-time */
  creationDate: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  publishDate?: string;
  /** @format date-time */
  activeFromDate?: string;
  /** @format date-time */
  cancellationDate?: string;
  /** @format date-time */
  settlementDate?: string;
  /** @format date-time */
  fullyFundedDate?: string;
  /** @format date-time */
  endDate?: string;
  isDemo?: boolean;
  maxFundingSatoshi: number;
  status: ResOpportunityDetailsV2DtoStatusEnum;
  collateralStatus: ResOpportunityDetailsV2DtoCollateralStatusEnum;
  walletInfo: ResOpportunityWalletInfoDto;
}

export interface ReqProcessDemoOpportunityEvents {
  opportunityId: string;
  payload: object;
}

export interface ResDemoFunding {
  id: string;
  lpId: string;
  amountSatoshi: number;
  /** @format date-time */
  fundingDate: string;
  companyName: string;
  email: string;
}

export interface ResDemoOpportunityFundings {
  entries: ResDemoFunding[];
}

export interface ResDemoCollateral {
  id: string;
  amountSatoshi: number;
  date: string;
  transactionType: ResDemoCollateralTransactionTypeEnum;
}

export interface ResDemoOpportunityCollaterals {
  entries: ResDemoCollateral[];
}

export interface ResDemoRewardDistribution {
  id: string;
  lpId: string;
  amountSatoshi: number;
  /** @format date-time */
  date: string;
  lpCompanyName: string;
  lpEmail: string;
}

export interface ResDemoReward {
  rewardId: string;
  amountSatoshi: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  status: ResDemoRewardStatusEnum;
  distributions: ResDemoRewardDistribution[];
}

export interface ResDemoOpportunityRewards {
  entries: ResDemoReward[];
}

export interface ResDemoExcessRewardTransaction {
  id: string;
  amountSatoshi: number;
  date: string;
}

export interface ResDemoExcessReward {
  value?: ResDemoExcessRewardTransaction;
}

export enum OpportunityAnyTypeStatus {
  PendingAction = "Pending Action",
  Ready = "Ready",
  Draft = "Draft",
  Active = "Active",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export interface GenericOpportunityBasic {
  id: string;
  status: GenericOpportunityBasicStatusEnum;
  type: GenericOpportunityBasicTypeEnum;
  idNum: number;
  minerId: string;
  minerName: string;
  remainingDuration?: string;
  fundingDeadline?: string;
  totalHashrate?: number;
  liquidityReceived?: LiquidityReceived;
  /** @format date-time */
  activeSince?: string;
  /** @format date-time */
  settlementDate?: string;
  /** @format date-time */
  agreementEndDate?: string;
  isDemo?: boolean;
}

export interface AllOpportunitiesSummaryDto {
  rows: GenericOpportunityBasic[];
  count: number;
}

export interface PatchOpportunityStatusDto {
  status: PatchOpportunityStatusDtoStatusEnum;
}

export interface NotificationReceiver {
  id: string;
  value: string;
}

export interface CreateNotificationDto {
  title: string;
  receiver: NotificationReceiver;
  priority: CreateNotificationDtoPriorityEnum;
  actionable: CreateNotificationDtoActionableEnum;
  link?: string;
  description: string;
}

export interface ValidReceiversResponse {
  receivers: NotificationReceiver[];
}

export interface ResTimelineTransactionDto {
  kind: ResTimelineTransactionDtoKindEnum;
  id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  approvalStatus: ResTimelineTransactionDtoApprovalStatusEnum;
  amountSatoshi: number;
  feeTakenSatoshi: number;
  fromAddress: string;
  toAddress: string;
  /** @format date-time */
  blockTimestamp?: string;
  txHash?: string;
  IsProcessed?: boolean;
  networkStatus?: ResTimelineTransactionDtoNetworkStatusEnum;
  rewardLpId?: string;
  rewardLpOrganizationId?: string;
  rewardLpName?: string;
}

export interface ResWalletAddressDto {
  kind: ResWalletAddressDtoKindEnum;
  address: string;
  walletOwnerId: string;
  name: string;
}

export interface ResTimelineDto {
  rows: ResTimelineTransactionDto[];
  addresses: ResWalletAddressDto[];
}

export interface BtcValueDto {
  btc: number;
  usd: number;
}

export interface PlatformDetailsDto {
  totalHashrate: number;
  totalValueLocked: BtcValueDto;
}

export interface AggregatedFeesDto {
  btc: number;
  usd: number;
}

export interface OpportunitiesSummaryDto {
  aggregatedFees30d: AggregatedFeesDto;
  aggregatedFees24h: AggregatedFeesDto;
}

export interface GetOpportunitiesSummaryResponseDto {
  platformDetails: PlatformDetailsDto;
  opportunitiesSummary: OpportunitiesSummaryDto;
}

export interface GetOpportunitySummaryResponseDto {
  liquidityReceived: BtcValueDto;
  hashrateAllocated: HashrateAllocatedDto;
  expectedReturn?: number;
}

export interface LiquidityDelivery {
  opportunityId: string;
  opportunityIdNum: number;
  minerCompanyName: string;
  requiredHashrateTh: number;
  newRequiredCollateralSatoshis: number;
  collateralBalanceSatoshis: number;
  isSufficientHashrateAllocated: boolean;
}

export interface LiquidityDeliveryResponseDto {
  rows: LiquidityDelivery[];
  count: number;
}

export interface LiquidityDeliveryRequestDto {
  opportunityId: string;
  isSufficientHashrateAllocated: boolean;
}

export interface ReqMinerRatingDto {
  minerId: string;
  financialHealthRate: ReqMinerRatingDtoFinancialHealthRateEnum;
  operationalHealthRate: ReqMinerRatingDtoOperationalHealthRateEnum;
  sustainabilityRate: ReqMinerRatingDtoSustainabilityRateEnum;
}

export interface ResMinerRatingDto {
  kind: boolean;
}

export interface BlockDto {
  height: number;
  rewardSatoshis: number;
  totalFeesSatoshis: number;
  timestamp: string;
  medianTimestamp: string;
  difficulty: number;
}

export interface ResBlocksDto {
  count: number;
  rows: BlockDto[];
}

export interface ComputedHashpriceDto {
  createdAt: string;
  day: string;
  effectiveDifficulty: number;
  totalSubsidySatoshis: number;
  totalFeesSatoshis: number;
  fppsHashpriceSatoshisPerTh: number;
  blockCount: number;
}

export interface ResComputedHashpricesDto {
  count: number;
  rows: ComputedHashpriceDto[];
}

export interface OpportunityRewardDto {
  rewardId: string;
  createdAt: string;
  /** @min 0 */
  amountSatoshis: number;
  distributionStatus: OpportunityRewardDtoDistributionStatusEnum;
  day: string;
}

export interface ResOpportunityRewardsDto {
  count: number;
  rows: OpportunityRewardDto[];
}

export interface DtoInternalTransaction {
  txId: string;
  createdAt: string;
  approvalStatus: DtoInternalTransactionApprovalStatusEnum;
  amountSatoshis: number;
  feeTakenSatoshis: number;
  txHash?: string;
  fromBitgoWalletId: string;
  toBitgoWalletId: string;
}

export interface RewardDetailsDto {
  rewardId: string;
  createdAt: string;
  /** @min 0 */
  amountSatoshis: number;
  distributionStatus: RewardDetailsDtoDistributionStatusEnum;
  day: string;
  distributions: DtoInternalTransaction[];
  liquidations: DtoInternalTransaction[];
}

export interface ResRewardDetails {
  payload?: RewardDetailsDto;
}

export interface AdminApiUserDto {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
}

export interface ResUsersDto {
  count: number;
  rows: AdminApiUserDto[];
}

export interface ReqAdminUserDto {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ResAdminUserDto {
  id: string;
}

export interface ReqExchangeRatesDto {
  timestamps: number[];
}

export interface ExchangeRate {
  ticker: string;
  rate: number;
}

export interface ResExchangeRatesFromEur {
  rates: ExchangeRate[];
}

export interface ResPredictionBreakdownDto {
  rewards: number;
  fees: number;
  subsidy: number;
  networkHashrate: number;
  feesRate: number;
  difficulty: number;
  blocks: number;
  subsidyRate: number;
}

export interface ResPredictionsDto {
  date: string;
  prediction: ResPredictionBreakdownDto;
  fifthPercentile: ResPredictionBreakdownDto;
  ninetyfifthPercentile: ResPredictionBreakdownDto;
}

export interface ResPredictionsPayload {
  rows: ResPredictionsDto[];
}

export interface ResHistoricalreakdownDto {
  rewards: number;
  fees: number;
  subsidy: number;
  networkHashrate: number;
  feesRate: number;
  difficulty: number;
  blocks: number;
  subsidyRate: number;
  date: string;
}

export interface ResHistoricalPayload {
  rows: ResHistoricalreakdownDto[];
}

export interface GroupDto {
  uuid: string;
  name: string;
  defaultPool: string;
  endpoint: string;
  port: number;
  members: GroupDto[];
}

export interface ContractByGroupDto {
  contracts: Record<string, ContractDto[]>;
}

export interface HashrateResponseDto {
  hashrate: number;
}

export interface StatusResponseDto {
  status: string;
}

export interface WorkerDto {
  workername: string;
  hashrate: number;
}

export interface WorkersResponseDto {
  workers: WorkerDto[];
}

export interface GroupAddRequestDto {
  name: string;
  defaultPool: string;
  endpoint: string;
  port: number;
  parentUUID?: string;
}

export interface ContractAddRequestDto {
  groupUUID: string;
  miningPoolURL: string;
  miningPoolUser: string;
  miningPoolPass: string;
  assignedHashrate: number;
  workernameOption: string;
}

export interface ContractDto {
  uuid: string;
  groupUUID: string;
  miningPoolURL: string;
  miningPoolUser: string;
  miningPoolPass: string;
  assignedHashrate: number;
  workernameOption: string;
}

export interface GroupUpdateRequestDto {
  uuid: string;
  defaultPool: string;
}

export interface MiningPoolCredentialsDto {
  poolUrl: string;
  workerIdMode: MiningPoolCredentialsDtoWorkerIdModeEnum;
}

export interface MiningPoolCredentialsResponseDto {
  credentials?: MiningPoolCredentialsDto;
}

export interface ProxyGroupResponseDto {
  proxyMiningAddress: string;
}

export interface GetProxyMiningAddressResponseDto {
  proxyMiningAddress?: string;
}

export interface ProxyHashrateResponseDto {
  currentHashratePhPerSecond: number;
  lastUpdated: string;
}

export interface ResEnterpriseSettingDto {
  settingsId?: string;
  requiredWhitelistApprovers?: number;
  requiredAccessApprovers?: number;
  managers?: Managers;
}

export interface EnterpriseSettingDto {
  organizationId: string;
  requiredWhitelistApprovers: number;
  requiredAccessApprovers: number;
}

export interface GetTransactionCsvResponse {
  url: string;
}

export enum ResTransactionDtoStatusEnum {
  Pending = "Pending",
  Confirmed = "Confirmed",
}

export enum ResTransactionDtoTransactionTypeEnum {
  RewardDistribution = "Reward Distribution",
  OpportunityFunding = "Opportunity Funding",
  LiquidityRefund = "Liquidity Refund",
  CollateralRelease = "Collateral Release",
  CollateralDeposit = "Collateral Deposit",
  RewardDelivery = "Reward Delivery",
  LiquidityDelivery = "Liquidity Delivery",
  ExcessRewardRelease = "Excess Reward Release",
  Unknown = "Unknown",
  CollateralLiquidation = "Collateral Liquidation",
  Deposit = "Deposit",
  Reconciliation = "Reconciliation",
  Withdrawal = "Withdrawal",
}

export enum WithdrawResponseDtoStatusEnum {
  Pending = "Pending",
  Confirmed = "Confirmed",
  AwaitingApproval = "Awaiting approval",
}

export enum BackfillAccountingDtoSortByEnum {
  HeightId = "heightId",
  Id = "id",
  Date = "date",
}

export enum BackfillAccountingDtoSortDirectionEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum NotificationResponseDtoPriorityEnum {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export enum NotificationResponseDtoActionableEnum {
  Yes = "yes",
  No = "no",
}

export enum OpportunityFundingResponseDtoApprovalStatusEnum {
  AwaitingAgreementSignature = "Awaiting agreement signature",
  AwaitingTransactionApproval = "Awaiting transaction approval",
  PendingTransaction = "Pending transaction",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum LpAgreementOpportunityDtoApprovalStatusEnum {
  AwaitingAgreementSignature = "Awaiting agreement signature",
  AwaitingTransactionApproval = "Awaiting transaction approval",
  PendingTransaction = "Pending transaction",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum UpdateFundingApprovalStatusDtoStatusEnum {
  AwaitingAgreementSignature = "Awaiting agreement signature",
  AwaitingTransactionApproval = "Awaiting transaction approval",
  PendingTransaction = "Pending transaction",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum OpportunityProgressDtoCurrentStageEnum {
  COMMIT_ADVANCE_AMOUNT = "COMMIT_ADVANCE_AMOUNT",
  SIGN_AGREEMENT = "SIGN_AGREEMENT",
  PROVIDE_ADVANCE_AMOUNT = "PROVIDE_ADVANCE_AMOUNT",
  PROVIDE_COLLATERAL = "PROVIDE_COLLATERAL",
  PROVIDE_HASHRATE = "PROVIDE_HASHRATE",
  CONFIRM_OPPORTUNITY_START = "CONFIRM_OPPORTUNITY_START",
  DELIVER_SUFFICIENT_REWARDS = "DELIVER_SUFFICIENT_REWARDS",
  RETURN_COLLATERAL = "RETURN_COLLATERAL",
  CEASE_HASHRATE_STREAMING = "CEASE_HASHRATE_STREAMING",
  RETURN_EXCESS_REWARDS = "RETURN_EXCESS_REWARDS",
  DONE = "DONE",
  CANCELLED = "CANCELLED",
  DEFAULTED = "DEFAULTED",
}

export enum OpportunityProgressDtoCollateralStatusEnum {
  Sufficient = "Sufficient",
  UnderSufficient = "Under sufficient",
}

export enum AuthUserResponseDtoUserTypeEnum {
  Admin = "Admin",
  Miner = "Miner",
  LP = "LP",
}

export enum AuthUserResponseDtoOnboardingStatusEnum {
  EntrypointPending = "Entrypoint pending",
  EntrypointSubmitted = "Entrypoint submitted",
  EntrypointRejected = "Entrypoint Rejected",
  KYCSubmittedCompany = "KYC submitted company",
  KYCPending = "KYC pending",
  KYCSubmittedRepresentative = "KYC submitted representative",
  KYCRejected = "KYC Rejected",
  KYMSubmittedGeneral = "KYM submitted general",
  KYMPending = "KYM pending",
  KYMRejected = "KYM Rejected",
  FullyOnboarded = "Fully onboarded",
  Susppended = "Susppended",
  None = "None",
}

export enum AuthUserResponseDtoOnboardingStatusAdminPanelEnum {
  None = "None",
  EntrypointPending = "Entrypoint Pending",
  EntrypointApproved = "Entrypoint Approved",
  EntrypointRejected = "Entrypoint Rejected",
  KYCAMLNotFullfilled = "KYC/AML Not Fullfilled",
  KYCAMLPending = "KYC/AML Pending",
  KYCAMLApproved = "KYC/AML Approved",
  KYCAMLRejected = "KYC/AML Rejected",
  KYMNotFullfilled = "KYM Not Fullfilled",
  KYMPending = "KYM Pending",
  KYMApproved = "KYM Approved",
  KYMRejected = "KYM Rejected",
  Onboarded = "Onboarded",
  Suspended = "Suspended",
}

export enum AuthUserResponseDtoRolesEnum {
  WalletManager = "Wallet Manager",
  OpportunityManager = "Opportunity Manager",
  AccessManager = "Access Manager",
}

export enum RejectOrAcceptWhitelistProposalDtoActionTypeEnum {
  Reject = "reject",
  Approve = "approve",
}

export enum WhitelistAddressApprovalDtoVerdictEnum {
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum WhitelistAddressProposalDtoApprovalStatusEnum {
  Open = "Open",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum OrganizationUserDtoRolesEnum {
  WalletManager = "Wallet Manager",
  OpportunityManager = "Opportunity Manager",
  AccessManager = "Access Manager",
}

export enum AccessApprovalDtoVerdictEnum {
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum CreateProposalDtoRoleEnum {
  WalletManager = "Wallet Manager",
  OpportunityManager = "Opportunity Manager",
  AccessManager = "Access Manager",
}

export enum RejectOrAcceptProposalDtoActionTypeEnum {
  Reject = "reject",
  Approve = "approve",
}

export enum ResLoanPaymentRequestDtoDestinationEnum {
  LP = "LP",
  Miner = "Miner",
  Admin = "Admin",
}

export enum ResLoanAssociatedValidAddressAddressTypeEnum {
  LpUserWallet = "LpUserWallet",
  LpApprovedAddress = "LpApprovedAddress",
  MinerUserWallet = "MinerUserWallet",
  MinerApprovedAddress = "MinerApprovedAddress",
}

export enum ResAdminLoanDtoStatusEnum {
  Draft = "Draft",
  Active = "Active",
  Ready = "Ready",
  UnderDelivered = "UnderDelivered",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum ResAdminLoanDtoCalculationKindEnum {
  DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT = "DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT",
  DAILY_PRINCIPAL_MONTHLY_PAYMENT = "DAILY_PRINCIPAL_MONTHLY_PAYMENT",
  DAILY_PRINCIPAL_DAILY_PAYMENT = "DAILY_PRINCIPAL_DAILY_PAYMENT",
  DAILY_INTEREST_THIRTY_DAYS_PAYMENT = "DAILY_INTEREST_THIRTY_DAYS_PAYMENT",
  DAILY_INTEREST_MONTHLY_PAYMENT = "DAILY_INTEREST_MONTHLY_PAYMENT",
  DAILY_INTEREST_DAILY_PAYMENT = "DAILY_INTEREST_DAILY_PAYMENT",
  CUSTOM_SCHEDULE = "CUSTOM_SCHEDULE",
}

export enum ReqGenerateDemoScenarioEnum {
  OverDelivery = "OverDelivery",
  UnderDelivery = "UnderDelivery",
  ExactDelivery = "ExactDelivery",
  Random = "Random",
}

export enum ResGenerateDemoStatusEnum {
  NotDemo = "NotDemo",
  Done = "Done",
  CanNoLongerGenerate = "CanNoLongerGenerate",
}

export enum ReqUpdateDraftLoanCalculationKindEnum {
  DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT = "DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT",
  DAILY_PRINCIPAL_MONTHLY_PAYMENT = "DAILY_PRINCIPAL_MONTHLY_PAYMENT",
  DAILY_PRINCIPAL_DAILY_PAYMENT = "DAILY_PRINCIPAL_DAILY_PAYMENT",
  DAILY_INTEREST_THIRTY_DAYS_PAYMENT = "DAILY_INTEREST_THIRTY_DAYS_PAYMENT",
  DAILY_INTEREST_MONTHLY_PAYMENT = "DAILY_INTEREST_MONTHLY_PAYMENT",
  DAILY_INTEREST_DAILY_PAYMENT = "DAILY_INTEREST_DAILY_PAYMENT",
  CUSTOM_SCHEDULE = "CUSTOM_SCHEDULE",
}

export enum ReqCreatePaymentRequestDestinationEnum {
  LP = "LP",
  Miner = "Miner",
  Admin = "Admin",
}

export enum ResDeletePaymentRequestStatusEnum {
  Done = "Done",
  TooLate = "TooLate",
}

export enum ResMinerLoanDtoStatusEnum {
  Draft = "Draft",
  Active = "Active",
  Ready = "Ready",
  UnderDelivered = "UnderDelivered",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum ResMinerLoanDtoCalculationKindEnum {
  DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT = "DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT",
  DAILY_PRINCIPAL_MONTHLY_PAYMENT = "DAILY_PRINCIPAL_MONTHLY_PAYMENT",
  DAILY_PRINCIPAL_DAILY_PAYMENT = "DAILY_PRINCIPAL_DAILY_PAYMENT",
  DAILY_INTEREST_THIRTY_DAYS_PAYMENT = "DAILY_INTEREST_THIRTY_DAYS_PAYMENT",
  DAILY_INTEREST_MONTHLY_PAYMENT = "DAILY_INTEREST_MONTHLY_PAYMENT",
  DAILY_INTEREST_DAILY_PAYMENT = "DAILY_INTEREST_DAILY_PAYMENT",
  CUSTOM_SCHEDULE = "CUSTOM_SCHEDULE",
}

export enum ResLpLoanDtoStatusEnum {
  Draft = "Draft",
  Active = "Active",
  Ready = "Ready",
  UnderDelivered = "UnderDelivered",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum ResLpLoanDtoCalculationKindEnum {
  DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT = "DAILY_PRINCIPAL_THIRTY_DAYS_PAYMENT",
  DAILY_PRINCIPAL_MONTHLY_PAYMENT = "DAILY_PRINCIPAL_MONTHLY_PAYMENT",
  DAILY_PRINCIPAL_DAILY_PAYMENT = "DAILY_PRINCIPAL_DAILY_PAYMENT",
  DAILY_INTEREST_THIRTY_DAYS_PAYMENT = "DAILY_INTEREST_THIRTY_DAYS_PAYMENT",
  DAILY_INTEREST_MONTHLY_PAYMENT = "DAILY_INTEREST_MONTHLY_PAYMENT",
  DAILY_INTEREST_DAILY_PAYMENT = "DAILY_INTEREST_DAILY_PAYMENT",
  CUSTOM_SCHEDULE = "CUSTOM_SCHEDULE",
}

export enum OpportunityBasicMinerFinancialHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum OpportunityBasicMinerOperationalHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum OpportunityBasicMinerSustainabilityRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum StreamingBasicStatusEnum {
  AwaitingCollateral = "Awaiting Collateral",
  AwaitingFunding = "Awaiting Funding",
  Draft = "Draft",
  Active = "Active",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Settled = "Settled",
  Closed = "Closed",
}

export enum ResPhysicalOpportunityDetailsDtoStatusEnum {
  Draft = "Draft",
  AwaitingCollateral = "AwaitingCollateral",
  Ready = "Ready",
  Active = "Active",
  UnderCollateralized = "UnderCollateralized",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Closed = "Closed",
}

export enum ProtocolWalletDtoWalletTypeEnum {
  SynHashPool = "SynHash Pool",
  CollateralVault = "Collateral Vault",
  RewardVault = "Reward Vault",
}

export enum OpportunitiesDetailsCollateralStatusEnum {
  Sufficient = "Sufficient",
  UnderSufficient = "Under sufficient",
}

export enum PreviewMinerBasicFinancialHealthRatingEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum PreviewMinerBasicOperationalHealthRatingEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum PreviewMinerBasicSustainabilityRatingEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum PrimaryContactDtoPrimaryContactKindEnum {
  SignupUser = "signup-user",
  AnotherPerson = "another-person",
  Notsure = "notsure",
}

export enum PrimaryContactDtoHasPowerOfAttorneyEnum {
  Yes = "yes",
  No = "no",
  Notsure = "notsure",
}

export enum RegisterLpDtoTimeHorizonOfLiquidityEnum {
  Notsure = "notsure",
  Lt1 = "lt1",
  Value1Mto3M = "1mto3m",
  Value3Mto6M = "3mto6m",
  Value6Mto1Y = "6mto1y",
  Gt1Y = "gt1y",
}

export enum RegisterMinerDtoTimeHorizonOfLiquidityEnum {
  Notsure = "notsure",
  Lt1 = "lt1",
  Value1Mto3M = "1mto3m",
  Value3Mto6M = "3mto6m",
  Value6Mto1Y = "6mto1y",
  Gt1Y = "gt1y",
}

export enum ResMinerKycApprovalDtoKindEnum {
  Success = "Success",
  WalletIssue = "WalletIssue",
}

export enum OrganizationEntryUserTypeEnum {
  Admin = "Admin",
  Miner = "Miner",
  LP = "LP",
}

export enum OrganizationEntryOnboardingStatusEnum {
  EntrypointPending = "Entrypoint pending",
  EntrypointSubmitted = "Entrypoint submitted",
  EntrypointRejected = "Entrypoint Rejected",
  KYCSubmittedCompany = "KYC submitted company",
  KYCPending = "KYC pending",
  KYCSubmittedRepresentative = "KYC submitted representative",
  KYCRejected = "KYC Rejected",
  KYMSubmittedGeneral = "KYM submitted general",
  KYMPending = "KYM pending",
  KYMRejected = "KYM Rejected",
  FullyOnboarded = "Fully onboarded",
  Susppended = "Susppended",
  None = "None",
}

export enum OrganizationEntryOnboardingStatusAdminPanelEnum {
  None = "None",
  EntrypointPending = "Entrypoint Pending",
  EntrypointApproved = "Entrypoint Approved",
  EntrypointRejected = "Entrypoint Rejected",
  KYCAMLNotFullfilled = "KYC/AML Not Fullfilled",
  KYCAMLPending = "KYC/AML Pending",
  KYCAMLApproved = "KYC/AML Approved",
  KYCAMLRejected = "KYC/AML Rejected",
  KYMNotFullfilled = "KYM Not Fullfilled",
  KYMPending = "KYM Pending",
  KYMApproved = "KYM Approved",
  KYMRejected = "KYM Rejected",
  Onboarded = "Onboarded",
  Suspended = "Suspended",
}

export enum BasicInfoDtoUserTypeEnum {
  Admin = "Admin",
  Miner = "Miner",
  LP = "LP",
}

export enum BasicInfoDtoOnboardingStatusEnum {
  EntrypointPending = "Entrypoint pending",
  EntrypointSubmitted = "Entrypoint submitted",
  EntrypointRejected = "Entrypoint Rejected",
  KYCSubmittedCompany = "KYC submitted company",
  KYCPending = "KYC pending",
  KYCSubmittedRepresentative = "KYC submitted representative",
  KYCRejected = "KYC Rejected",
  KYMSubmittedGeneral = "KYM submitted general",
  KYMPending = "KYM pending",
  KYMRejected = "KYM Rejected",
  FullyOnboarded = "Fully onboarded",
  Susppended = "Susppended",
  None = "None",
}

export enum BasicInfoDtoOnboardingStatusAdminPanelEnum {
  None = "None",
  EntrypointPending = "Entrypoint Pending",
  EntrypointApproved = "Entrypoint Approved",
  EntrypointRejected = "Entrypoint Rejected",
  KYCAMLNotFullfilled = "KYC/AML Not Fullfilled",
  KYCAMLPending = "KYC/AML Pending",
  KYCAMLApproved = "KYC/AML Approved",
  KYCAMLRejected = "KYC/AML Rejected",
  KYMNotFullfilled = "KYM Not Fullfilled",
  KYMPending = "KYM Pending",
  KYMApproved = "KYM Approved",
  KYMRejected = "KYM Rejected",
  Onboarded = "Onboarded",
  Suspended = "Suspended",
}

export enum LpResponseDtoProjectedAllocationEnum {
  Value0 = "0",
  From1To20 = "from1to20",
  From20To50 = "from20to50",
  Value50 = "50+",
}

export enum LpResponseDtoTimeHorizonOfLiquidityEnum {
  Lt1 = "lt1",
  Value1To6 = "1to6",
  Value6To2 = "6to2",
  Gt2 = "gt2",
}

export enum LpResponseDtoLiquidityHorizonEnum {
  Notsure = "notsure",
  Lt1 = "lt1",
  Value1Mto3M = "1mto3m",
  Value3Mto6M = "3mto6m",
  Value6Mto1Y = "6mto1y",
  Gt1Y = "gt1y",
}

export enum MinerResponseDtoPercentEncumbranceEnum {
  From1To25 = "from1to25",
  From25To50 = "from25to50",
  From50To75 = "from50to75",
  From75To99 = "from75to99",
  Full100 = "full100",
}

export enum MinerResponseDtoLiquidityAgreementDurationEnum {
  Lt6 = "lt6",
  Value6To12 = "6to12",
  Value12To18 = "12to18",
  Gt18 = "gt18",
}

export enum MinerResponseDtoLiquidityHorizonEnum {
  Notsure = "notsure",
  Lt1 = "lt1",
  Value1Mto3M = "1mto3m",
  Value3Mto6M = "3mto6m",
  Value6Mto1Y = "6mto1y",
  Gt1Y = "gt1y",
}

export enum MinerResponseDtoFinancialHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum MinerResponseDtoOperationalHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum MinerResponseDtoSustainabilityRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum ResMinerSummaryDtoOnboardingStatusEnum {
  EntrypointPending = "Entrypoint pending",
  EntrypointSubmitted = "Entrypoint submitted",
  EntrypointRejected = "Entrypoint Rejected",
  KYCSubmittedCompany = "KYC submitted company",
  KYCPending = "KYC pending",
  KYCSubmittedRepresentative = "KYC submitted representative",
  KYCRejected = "KYC Rejected",
  KYMSubmittedGeneral = "KYM submitted general",
  KYMPending = "KYM pending",
  KYMRejected = "KYM Rejected",
  FullyOnboarded = "Fully onboarded",
  Susppended = "Susppended",
  None = "None",
}

export enum ResMinerSummaryDtoFinancialHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum ResMinerSummaryDtoOperationalHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum ResMinerSummaryDtoSustainabilityRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum ResOpportunityDetailsV2DtoStatusEnum {
  AwaitingCollateral = "Awaiting Collateral",
  AwaitingFunding = "Awaiting Funding",
  Draft = "Draft",
  Active = "Active",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Settled = "Settled",
  Closed = "Closed",
}

export enum ResOpportunityDetailsV2DtoCollateralStatusEnum {
  Sufficient = "Sufficient",
  UnderSufficient = "Under sufficient",
}

export enum ResDemoCollateralTransactionTypeEnum {
  CollateralRelease = "Collateral Release",
  CollateralDeposit = "Collateral Deposit",
  CollateralLiquidation = "Collateral Liquidation",
}

export enum ResDemoRewardStatusEnum {
  Unprocessed = "Unprocessed",
  AwaitingCollateralLiquidation = "Awaiting collateral liquidation",
  AwaitingConfirmations = "Awaiting confirmations",
  Processed = "Processed",
  Failed = "Failed",
}

export enum GenericOpportunityBasicStatusEnum {
  PendingAction = "Pending Action",
  Ready = "Ready",
  Draft = "Draft",
  Active = "Active",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum GenericOpportunityBasicTypeEnum {
  SyntheticStreaming = "Synthetic Streaming",
  PhysicalStreaming = "Physical Streaming",
  Loan = "Loan",
}

export enum PatchOpportunityStatusDtoStatusEnum {
  AwaitingCollateral = "Awaiting Collateral",
  AwaitingFunding = "Awaiting Funding",
  Draft = "Draft",
  Active = "Active",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Settled = "Settled",
  Closed = "Closed",
}

export enum CreateNotificationDtoPriorityEnum {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export enum CreateNotificationDtoActionableEnum {
  Yes = "yes",
  No = "no",
}

export enum ResTimelineTransactionDtoKindEnum {
  RewardDistribution = "Reward Distribution",
  OpportunityFunding = "Opportunity Funding",
  LiquidityRefund = "Liquidity Refund",
  CollateralRelease = "Collateral Release",
  CollateralDeposit = "Collateral Deposit",
  RewardDelivery = "Reward Delivery",
  LiquidityDelivery = "Liquidity Delivery",
  ExcessRewardRelease = "Excess Reward Release",
  Unknown = "Unknown",
  CollateralLiquidation = "Collateral Liquidation",
}

export enum ResTimelineTransactionDtoApprovalStatusEnum {
  ApprovalNotRequired = "Approval not required",
  AwaitingApproval = "Awaiting approval",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum ResTimelineTransactionDtoNetworkStatusEnum {
  Pending = "Pending",
  Confirmed = "Confirmed",
}

export enum ResWalletAddressDtoKindEnum {
  OpportunitySynhashPool = "Opportunity synhash pool",
  OpportunityCollateralVault = "Opportunity collateral vault",
  OpportunityRewardVault = "Opportunity reward vault",
  ManagedMinerWallet = "Managed miner wallet",
  MinerWithdrawalWallet = "Miner withdrawal wallet",
  ManagedLPWallet = "Managed LP wallet",
  LPWithdrawalWallet = "LP withdrawal wallet",
}

export enum ReqMinerRatingDtoFinancialHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum ReqMinerRatingDtoOperationalHealthRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum ReqMinerRatingDtoSustainabilityRateEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum OpportunityRewardDtoDistributionStatusEnum {
  Unprocessed = "Unprocessed",
  AwaitingCollateralLiquidation = "Awaiting collateral liquidation",
  AwaitingConfirmations = "Awaiting confirmations",
  Processed = "Processed",
  Failed = "Failed",
}

export enum DtoInternalTransactionApprovalStatusEnum {
  ApprovalNotRequired = "Approval not required",
  AwaitingApproval = "Awaiting approval",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum RewardDetailsDtoDistributionStatusEnum {
  Unprocessed = "Unprocessed",
  AwaitingCollateralLiquidation = "Awaiting collateral liquidation",
  AwaitingConfirmations = "Awaiting confirmations",
  Processed = "Processed",
  Failed = "Failed",
}

export enum MiningPoolCredentialsDtoWorkerIdModeEnum {
  Passthrough = "passthrough",
  Username = "username",
  None = "none",
}

export enum GetWalletOverviewHistoryTxsParamsTxtypesEnum {
  RewardDistribution = "Reward Distribution",
  OpportunityFunding = "Opportunity Funding",
  LiquidityRefund = "Liquidity Refund",
  CollateralRelease = "Collateral Release",
  CollateralDeposit = "Collateral Deposit",
  RewardDelivery = "Reward Delivery",
  LiquidityDelivery = "Liquidity Delivery",
  ExcessRewardRelease = "Excess Reward Release",
  Unknown = "Unknown",
  CollateralLiquidation = "Collateral Liquidation",
  Deposit = "Deposit",
  Reconciliation = "Reconciliation",
  Withdrawal = "Withdrawal",
}

export enum GetWalletOverviewHistoryTxsParamsStatusEnum {
  Pending = "Pending",
  Confirmed = "Confirmed",
  AwaitingApproval = "Awaiting approval",
}

export enum GetWalletOverviewHistoryTxsParamsSortByTransactionDateTimeEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetWalletDepositTxsParamsSortEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetLpAgreementsParamsStatusEnum {
  AwaitingAgreementSignature = "Awaiting agreement signature",
  AwaitingTransactionApproval = "Awaiting transaction approval",
  PendingTransaction = "Pending transaction",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum GetLiquidityProvidersParamsDateEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetLiquidityProvidersV2ParamsDateEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum RemoveRoleParamsRoleEnum {
  WalletManager = "Wallet Manager",
  OpportunityManager = "Opportunity Manager",
  AccessManager = "Access Manager",
}

export enum GetMinerOpportunityHistoryTxsParamsTxtypesEnum {
  RewardDistribution = "Reward Distribution",
  OpportunityFunding = "Opportunity Funding",
  LiquidityRefund = "Liquidity Refund",
  CollateralRelease = "Collateral Release",
  CollateralDeposit = "Collateral Deposit",
  RewardDelivery = "Reward Delivery",
  LiquidityDelivery = "Liquidity Delivery",
  ExcessRewardRelease = "Excess Reward Release",
  Unknown = "Unknown",
  CollateralLiquidation = "Collateral Liquidation",
}

export enum GetMinerOpportunityHistoryTxsParamsStatusEnum {
  Pending = "Pending",
  Confirmed = "Confirmed",
  AwaitingApproval = "Awaiting approval",
}

export enum GetMinerOpportunityHistoryTxsParamsSortByTransactionDateTimeEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetLpOpprotunitiesFundedParamsHideSettledEnum {
  Yes = "yes",
  No = "no",
}

export enum GetLpOpprotunitiesFundedParamsEndDateEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetOrganizationsParamsUserTypeEnum {
  Admin = "Admin",
  Miner = "Miner",
  LP = "LP",
}

export enum GetOrganizationsParamsActionFirstEnum {
  Yes = "yes",
  No = "no",
}

export enum GetBlocksParamsOrderByEnum {
  Height = "height",
  Timestamp = "timestamp",
  Difficulty = "difficulty",
  RewardSatoshis = "rewardSatoshis",
}

export enum GetBlocksParamsOrderDirectionEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetComputedHashpricesParamsOrderByEnum {
  Day = "day",
  CreatedAt = "createdAt",
  EffectiveDifficulty = "effectiveDifficulty",
  TotalSubsidySatoshis = "totalSubsidySatoshis",
  FppsHashpriceSatoshisPerTh = "fppsHashpriceSatoshisPerTh",
  BlockCount = "blockCount",
}

export enum GetComputedHashpricesParamsOrderDirectionEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetOpportunityRewardsParamsOrderByEnum {
  CreatedAt = "createdAt",
  AmountSatoshis = "amountSatoshis",
  DistributionStatus = "distributionStatus",
}

export enum GetOpportunityRewardsParamsOrderDirectionEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum GetUsersParamsOrderByEnum {
  Id = "id",
  Email = "email",
  CreatedAt = "createdAt",
  LastName = "lastName",
  FirstName = "firstName",
  CellphoneNumber = "cellphoneNumber",
}

export enum GetUsersParamsOrderDirectionEnum {
  Asc = "asc",
  Desc = "desc",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://api.block.green.localhost:3000/v1";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Block Green API
 * @version 1.45.2
 * @baseUrl https://api.block.green.localhost:3000/v1
 * @externalDocs /openapi.yaml
 * @contact
 *
 * The back-end for Block Green.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  jobs = {
    /**
     * No description
     *
     * @tags jobs
     * @name JobsControllerSyncTransactions
     * @request GET:/jobs/sync-transactions
     * @secure
     */
    jobsControllerSyncTransactions: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/sync-transactions`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name JobsControllerSafeRewardCheck
     * @request GET:/jobs/reward-computation
     * @secure
     */
    jobsControllerSafeRewardCheck: (
      query: {
        skipBlockValidationFor: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/jobs/reward-computation`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name JobsControllerRewardDistribution
     * @request GET:/jobs/reward-distribution
     * @secure
     */
    jobsControllerRewardDistribution: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/reward-distribution`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name JobsControllerDailyUpdate
     * @request GET:/jobs/daily
     * @secure
     */
    jobsControllerDailyUpdate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/daily`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name JobsControllerAccessProposalCheck
     * @request GET:/jobs/access-proposal
     * @secure
     */
    jobsControllerAccessProposalCheck: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/access-proposal`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name JobsControllerWhitelistProposalCheck
     * @request GET:/jobs/whitelist-proposal
     * @secure
     */
    jobsControllerWhitelistProposalCheck: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/whitelist-proposal`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  wallet = {
    /**
     * No description
     *
     * @tags wallet
     * @name TransferCoins
     * @request POST:/wallet/transfer
     * @secure
     */
    transferCoins: (data: TransferRequestDto, params: RequestParams = {}) =>
      this.request<TransactionSendDto, any>({
        path: `/wallet/transfer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetFee
     * @request GET:/wallet/fee
     * @secure
     */
    getFee: (params: RequestParams = {}) =>
      this.request<FeeDto, any>({
        path: `/wallet/fee`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetWalletOverviewHistoryTxs
     * @request GET:/wallet/overview/txhistory
     * @secure
     */
    getWalletOverviewHistoryTxs: (
      query?: {
        txtypes?: GetWalletOverviewHistoryTxsParamsTxtypesEnum[];
        offset?: number;
        limit?: number;
        status?: GetWalletOverviewHistoryTxsParamsStatusEnum;
        sortByTransactionDateTime?: GetWalletOverviewHistoryTxsParamsSortByTransactionDateTimeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<OverviewHistoryTransactionsDto, any>({
        path: `/wallet/overview/txhistory`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name PostWalletWithdrawPreview
     * @request POST:/wallet/withdraw/preview
     * @secure
     */
    postWalletWithdrawPreview: (data: WithdrawRequestDto, params: RequestParams = {}) =>
      this.request<WithdrawResponseDto, any>({
        path: `/wallet/withdraw/preview`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name PostWalletWithdrawRequest
     * @request POST:/wallet/withdraw/request
     * @secure
     */
    postWalletWithdrawRequest: (data: WithdrawRequestDto, params: RequestParams = {}) =>
      this.request<WithdrawResponseDto, any>({
        path: `/wallet/withdraw/request`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetWalletDepositTxs
     * @request GET:/wallet/deposit
     * @secure
     */
    getWalletDepositTxs: (
      query?: {
        offset?: number;
        limit?: number;
        sort?: GetWalletDepositTxsParamsSortEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetDepositTxsResponseDto, any>({
        path: `/wallet/deposit`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetSatoshiToUsdPrice
     * @request GET:/wallet/price/satoshi-usd
     * @secure
     */
    getSatoshiToUsdPrice: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/wallet/price/satoshi-usd`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  transaction = {
    /**
     * No description
     *
     * @name BackfillAccountingDetails
     * @request POST:/transaction/backfill-accounting
     */
    backfillAccountingDetails: (data: BackfillAccountingDto, params: RequestParams = {}) =>
      this.request<BackfillAccountingResponseDto, any>({
        path: `/transaction/backfill-accounting`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notification = {
    /**
     * No description
     *
     * @tags notification
     * @name GetUserNotifications
     * @request GET:/notification
     * @secure
     */
    getUserNotifications: (params: RequestParams = {}) =>
      this.request<NotificationResponseDto[], any>({
        path: `/notification`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notification
     * @name MarkNotificationAsRead
     * @request PATCH:/notification
     * @secure
     */
    markNotificationAsRead: (data: MarkAsReadDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notification`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  opportunity = {
    /**
     * No description
     *
     * @tags opportunity
     * @name InitiateOpportunityFunding
     * @request POST:/opportunity/initiate-funding
     * @secure
     */
    initiateOpportunityFunding: (data: InitiateOpportunityFunding, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/opportunity/initiate-funding`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetOpportunityFunding
     * @request GET:/opportunity/{id}/funding
     * @secure
     */
    getOpportunityFunding: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityFundingResponseDto, any>({
        path: `/opportunity/${id}/funding`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetLpAgreements
     * @request GET:/opportunity/lp-agreements
     * @secure
     */
    getLpAgreements: (
      query?: {
        offset?: number;
        limit?: number;
        status?: GetLpAgreementsParamsStatusEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<LpAgreementOpportunitiesPageDto, any>({
        path: `/opportunity/lp-agreements`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name UpdateFundingApprovalStatus
     * @request PATCH:/opportunity/funding/{id}
     * @secure
     */
    updateFundingApprovalStatus: (id: string, data: UpdateFundingApprovalStatusDto, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/opportunity/funding/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetLiquidityProviders
     * @request GET:/opportunity/{id}/lp
     * @secure
     */
    getLiquidityProviders: (
      id: string,
      query?: {
        offset?: number;
        limit?: number;
        date?: GetLiquidityProvidersParamsDateEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/opportunity/${id}/lp`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetLiquidityProvidersV2
     * @request GET:/opportunity/{id}/lp/v2
     * @secure
     */
    getLiquidityProvidersV2: (
      id: string,
      query?: {
        offset?: number;
        limit?: number;
        date?: GetLiquidityProvidersV2ParamsDateEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResBasicLiquidityProviders, any>({
        path: `/opportunity/${id}/lp/v2`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name UpdateFunding
     * @request PATCH:/opportunity/funding
     * @secure
     */
    updateFunding: (data: UpdateOpportunityFunding, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/opportunity/funding`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetCollateral
     * @request GET:/opportunity/{id}/collateral-info
     * @secure
     */
    getCollateral: (id: string, params: RequestParams = {}) =>
      this.request<CollateralInfoDto, any>({
        path: `/opportunity/${id}/collateral-info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetOpportunityProgress
     * @request GET:/opportunity/{id}/progress
     * @secure
     */
    getOpportunityProgress: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityProgressDto, any>({
        path: `/opportunity/${id}/progress`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name GetUser
     * @request GET:/user
     * @secure
     */
    getUser: (params: RequestParams = {}) =>
      this.request<AuthUserResponseDto, any>({
        path: `/user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PostUserPassword
     * @request POST:/user/password
     * @secure
     */
    postUserPassword: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/password`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserMiners
     * @request GET:/user/miners
     * @secure
     */
    getUserMiners: (params: RequestParams = {}) =>
      this.request<MinerListDto, any>({
        path: `/user/miners`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PutUserAddressToWhitelist
     * @request POST:/user/addresses/whitelist
     * @secure
     */
    putUserAddressToWhitelist: (data: WithdrawalAddressDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/addresses/whitelist`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name RejectOrApproveWhitelistProposal
     * @request PATCH:/user/addresses/whitelist
     * @secure
     */
    rejectOrApproveWhitelistProposal: (data: RejectOrAcceptWhitelistProposalDto, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/user/addresses/whitelist`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserWhitelistedAddress
     * @request GET:/user/addresses/whitelist
     * @secure
     */
    getUserWhitelistedAddress: (
      query?: {
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetWhitelistedAddressesResponseDto, any>({
        path: `/user/addresses/whitelist`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name RemoveWhitelistAddress
     * @request DELETE:/user/addresses/whitelist
     * @secure
     */
    removeWhitelistAddress: (
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/addresses/whitelist`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetWhitelistProposal
     * @request GET:/user/addresses/whitelist/proposal
     * @secure
     */
    getWhitelistProposal: (
      query?: {
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetWhitelistProposalResponseDto, any>({
        path: `/user/addresses/whitelist/proposal`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetOrganizationUsers
     * @request GET:/user/organization
     * @secure
     */
    getOrganizationUsers: (
      query?: {
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetOrganizationUsersResponseDto, any>({
        path: `/user/organization`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name InviteOrganizationUser
     * @request POST:/user/organization
     * @secure
     */
    inviteOrganizationUser: (data: InviteOrganizationUserDto, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/user/organization`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name RemoveOrganizationUser
     * @request DELETE:/user/organization
     * @secure
     */
    removeOrganizationUser: (
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/organization`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetAccessManagerCount
     * @request GET:/user/organization/access-managers
     * @secure
     */
    getAccessManagerCount: (params: RequestParams = {}) =>
      this.request<ManagerCountDto, any>({
        path: `/user/organization/access-managers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetManagerProposals
     * @request GET:/user/organization/access-proposals
     * @secure
     */
    getManagerProposals: (
      query?: {
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetManagerProposalsResponseDto, any>({
        path: `/user/organization/access-proposals`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ProposeNewRole
     * @request POST:/user/organization/access-proposals
     * @secure
     */
    proposeNewRole: (data: CreateProposalDto, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/user/organization/access-proposals`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name RejectOrApproveProposal
     * @request POST:/user/organization/access-approval
     * @secure
     */
    rejectOrApproveProposal: (data: RejectOrAcceptProposalDto, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/user/organization/access-approval`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name RemoveRole
     * @request DELETE:/user/role
     * @secure
     */
    removeRole: (
      query: {
        role: RemoveRoleParamsRoleEnum;
        userId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<LoanBasic, any>({
        path: `/user/role`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  rewardComputation = {
    /**
     * No description
     *
     * @tags reward-computation
     * @name RewardComputationControllerRewardCheck
     * @request GET:/reward-computation
     * @secure
     */
    rewardComputationControllerRewardCheck: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reward-computation`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  loans = {
    /**
     * No description
     *
     * @tags loans
     * @name GetAdminAllLoans
     * @request GET:/loans/admin/loans
     * @secure
     */
    getAdminAllLoans: (params: RequestParams = {}) =>
      this.request<ResAdminLoans, any>({
        path: `/loans/admin/loans`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CreateAdminDraftLoan
     * @request POST:/loans/admin/loans
     * @secure
     */
    createAdminDraftLoan: (data: ReqCreateDraftLoanDto, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loans`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GenerateDemoLoanData
     * @request PUT:/loans/admin/loans/demo
     * @secure
     */
    generateDemoLoanData: (data: ReqGenerateDemo, params: RequestParams = {}) =>
      this.request<ResGenerateDemo, any>({
        path: `/loans/admin/loans/demo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetAdminLoanDetails
     * @request GET:/loans/admin/loan/{id}
     * @secure
     */
    getAdminLoanDetails: (id: string, params: RequestParams = {}) =>
      this.request<ResAdminLoanDto, any>({
        path: `/loans/admin/loan/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetAdminLoanBitgoBalance
     * @request GET:/loans/admin/loan/{id}/bitgo-balance
     * @secure
     */
    getAdminLoanBitgoBalance: (id: string, params: RequestParams = {}) =>
      this.request<ResLoanBitgoBalance, any>({
        path: `/loans/admin/loan/${id}/bitgo-balance`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name UpdateAdminDraftLoan
     * @request PUT:/loans/admin/loans/draft
     * @secure
     */
    updateAdminDraftLoan: (data: ReqUpdateDraftLoan, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loans/draft`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CancelAdminLoan
     * @request PUT:/loans/admin/loan/{id}/cancel
     * @secure
     */
    cancelAdminLoan: (id: string, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loan/${id}/cancel`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CompleteAdminLoan
     * @request PUT:/loans/admin/loan/{id}/complete
     * @secure
     */
    completeAdminLoan: (id: string, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loan/${id}/complete`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CreateAdminPaymentRequest
     * @request POST:/loans/admin/loans/payments
     * @secure
     */
    createAdminPaymentRequest: (data: ReqCreatePaymentRequest, params: RequestParams = {}) =>
      this.request<ResCreatePaymentRequest, any>({
        path: `/loans/admin/loans/payments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name DeleteAdminPaymentRequest
     * @request DELETE:/loans/admin/loans/payments/{id}
     * @secure
     */
    deleteAdminPaymentRequest: (id: string, params: RequestParams = {}) =>
      this.request<ResDeletePaymentRequest, any>({
        path: `/loans/admin/loans/payments/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetMinerLoans
     * @request GET:/loans/miner/loans
     * @secure
     */
    getMinerLoans: (params: RequestParams = {}) =>
      this.request<ResMinerLoans, any>({
        path: `/loans/miner/loans`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetMinerLoan
     * @request GET:/loans/miner/loans/{id}/details
     * @secure
     */
    getMinerLoan: (id: string, params: RequestParams = {}) =>
      this.request<ResMinerLoanDto, any>({
        path: `/loans/miner/loans/${id}/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetLpLoans
     * @request GET:/loans/lp/loans
     * @secure
     */
    getLpLoans: (params: RequestParams = {}) =>
      this.request<ResLpLoans, any>({
        path: `/loans/lp/loans`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetLpLoan
     * @request GET:/loans/lp/loans/{id}/details
     * @secure
     */
    getLpLoan: (id: string, params: RequestParams = {}) =>
      this.request<ResLpLoanDto, any>({
        path: `/loans/lp/loans/${id}/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  loanJobs = {
    /**
     * No description
     *
     * @tags loans, jobs
     * @name JobsControllerSyncTransactions
     * @request GET:/loan-jobs/sync-transactions
     * @secure
     */
    jobsControllerSyncTransactions: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/loan-jobs/sync-transactions`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans, jobs
     * @name JobsControllerPaymentExecution
     * @request GET:/loan-jobs/execute-payments
     * @secure
     */
    jobsControllerPaymentExecution: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/loan-jobs/execute-payments`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans, jobs
     * @name JobsControllerUnderDeliveryCheck
     * @request GET:/loan-jobs/under-delivery-check
     * @secure
     */
    jobsControllerUnderDeliveryCheck: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/loan-jobs/under-delivery-check`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  miner = {
    /**
     * No description
     *
     * @tags miner
     * @name GetMinerOpportunities
     * @request GET:/miner/opportunities
     */
    getMinerOpportunities: (
      query?: {
        offset?: number;
        limit?: number;
        hideSettled?: YesOrNoChoice;
        status?: OpportunityStatus;
        actionFirst?: YesOrNoChoice;
        activeSince?: Sort;
        settlement?: Sort;
      },
      params: RequestParams = {}
    ) =>
      this.request<StreamingOpportunitiesDto, any>({
        path: `/miner/opportunities`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags miner
     * @name GetMinerOpportunityHistoryTxs
     * @request GET:/miner/opportunities/{id}/txhistory
     */
    getMinerOpportunityHistoryTxs: (
      id: string,
      query?: {
        txtypes?: GetMinerOpportunityHistoryTxsParamsTxtypesEnum[];
        offset?: number;
        limit?: number;
        status?: GetMinerOpportunityHistoryTxsParamsStatusEnum;
        sortByTransactionDateTime?: GetMinerOpportunityHistoryTxsParamsSortByTransactionDateTimeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<OverviewHistoryTransactionsDto, any>({
        path: `/miner/opportunities/${id}/txhistory`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags miner
     * @name PostCollateralToOpportunity
     * @request POST:/miner/opportunities/{id}/collateral
     */
    postCollateralToOpportunity: (id: string, data: CollateralRequestDto, params: RequestParams = {}) =>
      this.request<TransactionSendDto, any>({
        path: `/miner/opportunities/${id}/collateral`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags miner
     * @name GetMinerOpportunitiesSummary
     * @request GET:/miner/opportunities/summary
     */
    getMinerOpportunitiesSummary: (params: RequestParams = {}) =>
      this.request<OpportunitiesMinerSummaryDto, any>({
        path: `/miner/opportunities/summary`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags miner
     * @name GetMinerOpportunitySummary
     * @request GET:/miner/opportunity/{id}/summary
     */
    getMinerOpportunitySummary: (id: string, params: RequestParams = {}) =>
      this.request<GetMinerOpportunitySummaryResponseDto, any>({
        path: `/miner/opportunity/${id}/summary`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags miner
     * @name GetMinerOnboardingBasicDetails
     * @request GET:/miner/onboarding/basic
     */
    getMinerOnboardingBasicDetails: (params: RequestParams = {}) =>
      this.request<ResBasicOnboardingMinerDetailsDto, any>({
        path: `/miner/onboarding/basic`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  physicalStreamingJobs = {
    /**
     * No description
     *
     * @tags physical-streaming, jobs
     * @name JobsControllerPaymentExecution
     * @request GET:/physical-streaming-jobs/execute-payments
     * @secure
     */
    jobsControllerPaymentExecution: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/physical-streaming-jobs/execute-payments`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  physicalStreaming = {
    /**
     * No description
     *
     * @tags physical-streaming
     * @name CreateDraftOpportunity
     * @request POST:/physical-streaming/admin/opportunities
     * @secure
     */
    createDraftOpportunity: (data: ReqCreatePhysicalOpportunityDto, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/physical-streaming/admin/opportunities`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags physical-streaming
     * @name UpdateDraftOpportunity
     * @request PUT:/physical-streaming/admin/opportunities/{id}
     * @secure
     */
    updateDraftOpportunity: (id: string, data: ReqUpdatePhysicalOpportunityDto, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/physical-streaming/admin/opportunities/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags physical-streaming
     * @name GetOpportunityDetails
     * @request GET:/physical-streaming/admin/opportunities/{id}
     * @secure
     */
    getOpportunityDetails: (id: string, params: RequestParams = {}) =>
      this.request<ResPhysicalOpportunityDetailsDto, any>({
        path: `/physical-streaming/admin/opportunities/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  lp = {
    /**
     * No description
     *
     * @tags lp
     * @name GetLpOpprotunities
     * @request GET:/lp/opportunities
     * @secure
     */
    getLpOpprotunities: (
      query?: {
        newestFirst?: YesOrNoChoice;
      },
      params: RequestParams = {}
    ) =>
      this.request<LPOpportunitiesDto, any>({
        path: `/lp/opportunities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpOpprotunity
     * @request GET:/lp/opportunity/{id}
     * @secure
     */
    getLpOpprotunity: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityFullDataDto, any>({
        path: `/lp/opportunity/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPreviewStreamingOpportunity
     * @request GET:/lp/preview-streaming-opportunity/{id}
     * @secure
     */
    getLpPreviewStreamingOpportunity: (id: string, params: RequestParams = {}) =>
      this.request<PreviewStreamingOpportunityDataDto, any>({
        path: `/lp/preview-streaming-opportunity/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPreviewLoan
     * @request GET:/lp/preview-loan/{id}
     * @secure
     */
    getLpPreviewLoan: (id: string, params: RequestParams = {}) =>
      this.request<PreviewLoanDataDto, any>({
        path: `/lp/preview-loan/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPreviewLease
     * @request GET:/lp/preview-lease/{id}
     * @secure
     */
    getLpPreviewLease: (id: string, params: RequestParams = {}) =>
      this.request<PreviewLeaseDataDto, any>({
        path: `/lp/preview-lease/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpOpprotunitiesFunded
     * @request GET:/lp/opportunities/funded
     * @secure
     */
    getLpOpprotunitiesFunded: (
      query?: {
        offset?: number;
        limit?: number;
        minerId?: string;
        hideSettled?: GetLpOpprotunitiesFundedParamsHideSettledEnum;
        endDate?: GetLpOpprotunitiesFundedParamsEndDateEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<OpportunityFundedResponseDto, any>({
        path: `/lp/opportunities/funded`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name RequestAccessToDataRoom
     * @request POST:/lp/dataroom
     * @secure
     */
    requestAccessToDataRoom: (data: ReqDataroomAccessDto, params: RequestParams = {}) =>
      this.request<ResDataroomAccessDto, any>({
        path: `/lp/dataroom`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPortfolioChart
     * @request GET:/lp/portfolio/chart
     * @secure
     */
    getLpPortfolioChart: (
      query: {
        year: string;
        minerId?: string;
        month: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetLpPortfolioChartResponseDto, any>({
        path: `/lp/portfolio/chart`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetPortfolioSummary
     * @request GET:/lp/portfolio/summary
     * @secure
     */
    getPortfolioSummary: (params: RequestParams = {}) =>
      this.request<GetPortfolioSummaryResponseDto, any>({
        path: `/lp/portfolio/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetOpportunitiesSummary
     * @request GET:/lp/summary/opportunities
     * @secure
     */
    getOpportunitiesSummary: (params: RequestParams = {}) =>
      this.request<GetNetworkStatsResponseDto, any>({
        path: `/lp/summary/opportunities`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetOpportynitySummary
     * @request GET:/lp/opportunity/{id}/summary
     * @secure
     */
    getOpportynitySummary: (id: string, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/lp/opportunity/${id}/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  entrypoint = {
    /**
     * @description Registration of liquidity providers, enforced by BG validation. Notifications are sent on success.
     *
     * @tags entrypoint
     * @name RegisterLp
     * @request POST:/entrypoint/registration/lp
     */
    registerLp: (data: RegisterLpDto, params: RequestParams = {}) =>
      this.request<ResOnboardingDto, any>({
        path: `/entrypoint/registration/lp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Registration of miners, enforced by BG validation. Notifications are sent on success.
     *
     * @tags entrypoint
     * @name RegisterMiner
     * @request POST:/entrypoint/registration/miner
     */
    registerMiner: (data: RegisterMinerDto, params: RequestParams = {}) =>
      this.request<ResOnboardingDto, any>({
        path: `/entrypoint/registration/miner`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  kym = {
    /**
     * No description
     *
     * @tags kym
     * @name GetKym2Financials
     * @request GET:/kym/v2/financials
     * @secure
     */
    getKym2Financials: (params: RequestParams = {}) =>
      this.request<ResKymFinancialsDto, any>({
        path: `/kym/v2/financials`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2Financials
     * @request PUT:/kym/v2/financials
     * @secure
     */
    updateKym2Financials: (data: ReqUpdateKymFinancialsDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/financials`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2Hosting
     * @request GET:/kym/v2/hosting
     * @secure
     */
    getKym2Hosting: (params: RequestParams = {}) =>
      this.request<UpdateKymHostingDto, any>({
        path: `/kym/v2/hosting`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2Hosting
     * @request PUT:/kym/v2/hosting
     * @secure
     */
    updateKym2Hosting: (data: UpdateKymHostingDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/hosting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2Asic
     * @request GET:/kym/v2/asic
     * @secure
     */
    getKym2Asic: (params: RequestParams = {}) =>
      this.request<ResKymAsicDto, any>({
        path: `/kym/v2/asic`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2Asic
     * @request PUT:/kym/v2/asic
     * @secure
     */
    updateKym2Asic: (data: ReqUpdateKymAsicDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/asic`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2EnergyProfile
     * @request GET:/kym/v2/energy-profile
     * @secure
     */
    getKym2EnergyProfile: (params: RequestParams = {}) =>
      this.request<ResKymEnergyProfileDto, any>({
        path: `/kym/v2/energy-profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2EnergyProfile
     * @request PUT:/kym/v2/energy-profile
     * @secure
     */
    updateKym2EnergyProfile: (data: ReqKymEnergyProfileDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/energy-profile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2CapacityAndSites
     * @request GET:/kym/v2/capacity-sites
     * @secure
     */
    getKym2CapacityAndSites: (params: RequestParams = {}) =>
      this.request<ResKymCapacityAndSitesDto, any>({
        path: `/kym/v2/capacity-sites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2CapacityAndSites
     * @request PUT:/kym/v2/capacity-sites
     * @secure
     */
    updateKym2CapacityAndSites: (data: ReqUpdateKymCapacityAndSitesDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/capacity-sites`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags admin
     * @name PatchOrganizationStatus
     * @request PATCH:/admin/organizations/{id}
     * @secure
     */
    patchOrganizationStatus: (
      id: string,
      query: {
        approved: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/organizations/${id}`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name ApproveMinerOnboarding
     * @request PATCH:/admin/organizations/onboarding/{id}/approval
     * @secure
     */
    approveMinerOnboarding: (id: string, params: RequestParams = {}) =>
      this.request<ResUpdateStatus, any>({
        path: `/admin/organizations/onboarding/${id}/approval`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name RejectMinerOnboarding
     * @request PATCH:/admin/organizations/{id}/rejection
     * @secure
     */
    rejectMinerOnboarding: (id: string, params: RequestParams = {}) =>
      this.request<ResUpdateStatus, any>({
        path: `/admin/organizations/${id}/rejection`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name ApproveMinerKycAndGenerateWallet
     * @request PATCH:/admin/organizations/miner/approval
     * @secure
     */
    approveMinerKycAndGenerateWallet: (data: ReqByMinerIdDto, params: RequestParams = {}) =>
      this.request<ResMinerKycApprovalDto, any>({
        path: `/admin/organizations/miner/approval`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetOrganizations
     * @request GET:/admin/organizations
     * @secure
     */
    getOrganizations: (
      query?: {
        offset?: number;
        limit?: number;
        userType?: GetOrganizationsParamsUserTypeEnum;
        actionFirst?: GetOrganizationsParamsActionFirstEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrganizationsResponseDto, any>({
        path: `/admin/organizations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetLpById
     * @request GET:/admin/organizations/lp/{id}
     * @secure
     */
    getLpById: (id: string, params: RequestParams = {}) =>
      this.request<LpOrganizationResponseDto, any>({
        path: `/admin/organizations/lp/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerById
     * @request GET:/admin/organizations/miner/{id}
     * @secure
     */
    getMinerById: (id: string, params: RequestParams = {}) =>
      this.request<MinerInfoResponseDto, any>({
        path: `/admin/organizations/miner/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerSummary
     * @request GET:/admin/organizations/miner/{id}/summary
     * @secure
     */
    getMinerSummary: (id: string, params: RequestParams = {}) =>
      this.request<ResMinerSummaryDto, any>({
        path: `/admin/organizations/miner/${id}/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name DumpMinerData
     * @request POST:/admin/organizations/miner/{id}/dump
     * @secure
     */
    dumpMinerData: (id: string, params: RequestParams = {}) =>
      this.request<ResMinerDumpDto, any>({
        path: `/admin/organizations/miner/${id}/dump`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GenerateFileSignedUrl
     * @request PUT:/admin/files/{id}
     * @secure
     */
    generateFileSignedUrl: (id: string, params: RequestParams = {}) =>
      this.request<ResDownloadUrlDto, any>({
        path: `/admin/files/${id}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name CreateOpportunityV2
     * @request POST:/admin/opportunities/v2
     * @secure
     */
    createOpportunityV2: (data: ReqCreateOpportunityV2Dto, params: RequestParams = {}) =>
      this.request<ResCreateOpportunityV2Dto, any>({
        path: `/admin/opportunities/v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name UpdateOpportunityV2
     * @request PUT:/admin/opportunities/v2
     * @secure
     */
    updateOpportunityV2: (data: ReqUpdateOpportunityV2Dto, params: RequestParams = {}) =>
      this.request<ResCreateOpportunityV2Dto, any>({
        path: `/admin/opportunities/v2`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunityV2
     * @request GET:/admin/opportunities/{id}/v2
     * @secure
     */
    getAdminOpportunityV2: (id: string, params: RequestParams = {}) =>
      this.request<ResOpportunityDetailsV2Dto, any>({
        path: `/admin/opportunities/${id}/v2`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name ProcessDemoOpportunityEvents
     * @request PUT:/admin/opportunities/demo
     * @secure
     */
    processDemoOpportunityEvents: (data: ReqProcessDemoOpportunityEvents, params: RequestParams = {}) =>
      this.request<ResCreateOpportunityV2Dto, any>({
        path: `/admin/opportunities/demo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoOpportunityApprovedFundings
     * @request GET:/admin/opportunities/demo/{id}/fundings
     * @secure
     */
    getDemoOpportunityApprovedFundings: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoOpportunityFundings, any>({
        path: `/admin/opportunities/demo/${id}/fundings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoCollateralTransactions
     * @request GET:/admin/opportunities/demo/{id}/collaterals
     * @secure
     */
    getDemoCollateralTransactions: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoOpportunityCollaterals, any>({
        path: `/admin/opportunities/demo/${id}/collaterals`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoOpportunityRewards
     * @request GET:/admin/opportunities/demo/{id}/rewards
     * @secure
     */
    getDemoOpportunityRewards: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoOpportunityRewards, any>({
        path: `/admin/opportunities/demo/${id}/rewards`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoExcessReward
     * @request GET:/admin/opportunities/demo/{id}/excess-reward
     * @secure
     */
    getDemoExcessReward: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoExcessReward, any>({
        path: `/admin/opportunities/demo/${id}/excess-reward`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunities
     * @request GET:/admin/opportunities
     * @secure
     */
    getAdminOpportunities: (
      query?: {
        offset?: number;
        limit?: number;
        hideSettled?: YesOrNoChoice;
        status?: OpportunityStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<StreamingOpportunitiesDto, any>({
        path: `/admin/opportunities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminAllOpportunities
     * @request GET:/admin/opportunities/all
     * @secure
     */
    getAdminAllOpportunities: (
      query?: {
        status?: OpportunityAnyTypeStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<AllOpportunitiesSummaryDto, any>({
        path: `/admin/opportunities/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PatchAdminOpportunityStatus
     * @request PATCH:/admin/opportunities/{id}/status
     * @secure
     */
    patchAdminOpportunityStatus: (id: string, data: PatchOpportunityStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/opportunities/${id}/status`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunityStatus
     * @request GET:/admin/opportunities/{id}/status
     * @secure
     */
    getAdminOpportunityStatus: (id: string, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/admin/opportunities/${id}/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunity
     * @request GET:/admin/opportunities/{id}
     * @secure
     */
    getAdminOpportunity: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityFullDataDto, any>({
        path: `/admin/opportunities/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PutAdminNotification
     * @request PUT:/admin/notifications
     * @secure
     */
    putAdminNotification: (data: CreateNotificationDto, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/admin/notifications`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminNotificationReceivers
     * @request GET:/admin/notifications/receivers
     * @secure
     */
    getAdminNotificationReceivers: (params: RequestParams = {}) =>
      this.request<ValidReceiversResponse, any>({
        path: `/admin/notifications/receivers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAllOpportunityTransactions
     * @request GET:/admin/opportunities/{id}/transactions
     * @secure
     */
    getAllOpportunityTransactions: (id: string, params: RequestParams = {}) =>
      this.request<ResTimelineDto, any>({
        path: `/admin/opportunities/${id}/transactions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunitiesSummary
     * @request GET:/admin/summary/opportunities
     * @secure
     */
    getAdminOpportunitiesSummary: (params: RequestParams = {}) =>
      this.request<GetOpportunitiesSummaryResponseDto, any>({
        path: `/admin/summary/opportunities`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunitySummary
     * @request GET:/admin/opportunity/{id}/summary
     * @secure
     */
    getAdminOpportunitySummary: (id: string, params: RequestParams = {}) =>
      this.request<GetOpportunitySummaryResponseDto, any>({
        path: `/admin/opportunity/${id}/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminLiquidityDelivery
     * @request GET:/admin/liquidity-delivery
     * @secure
     */
    getAdminLiquidityDelivery: (
      query?: {
        offset?: number;
        limit?: number;
        hideSettled?: YesOrNoChoice;
      },
      params: RequestParams = {}
    ) =>
      this.request<LiquidityDeliveryResponseDto, any>({
        path: `/admin/liquidity-delivery`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PatchAdminLiquidityDelivery
     * @request PATCH:/admin/liquidity-delivery
     * @secure
     */
    patchAdminLiquidityDelivery: (data: LiquidityDeliveryRequestDto, params: RequestParams = {}) =>
      this.request<LoanBasic, any>({
        path: `/admin/liquidity-delivery`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2Financials
     * @request GET:/admin/miner/kym/financials/{orgId}
     * @secure
     */
    getMinerKym2Financials: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymFinancialsDto, any>({
        path: `/admin/miner/kym/financials/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2Hosting
     * @request GET:/admin/miner/kym/hosting/{orgId}
     * @secure
     */
    getMinerKym2Hosting: (orgId: string, params: RequestParams = {}) =>
      this.request<UpdateKymHostingDto, any>({
        path: `/admin/miner/kym/hosting/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2Asic
     * @request GET:/admin/miner/kym/asic/{orgId}
     * @secure
     */
    getMinerKym2Asic: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymAsicDto, any>({
        path: `/admin/miner/kym/asic/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2EnergyProfile
     * @request GET:/admin/miner/kym/energy-profile/{orgId}
     * @secure
     */
    getMinerKym2EnergyProfile: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymEnergyProfileDto, any>({
        path: `/admin/miner/kym/energy-profile/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2CapacityAndSites
     * @request GET:/admin/miner/kym/capacity-sites/{orgId}
     * @secure
     */
    getMinerKym2CapacityAndSites: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymCapacityAndSitesDto, any>({
        path: `/admin/miner/kym/capacity-sites/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name UpdateMinerRating
     * @request PUT:/admin/miner/rating
     * @secure
     */
    updateMinerRating: (data: ReqMinerRatingDto, params: RequestParams = {}) =>
      this.request<ResMinerRatingDto, any>({
        path: `/admin/miner/rating`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetBlocks
     * @request GET:/admin/blocks
     * @secure
     */
    getBlocks: (
      query: {
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
        orderBy: GetBlocksParamsOrderByEnum;
        orderDirection: GetBlocksParamsOrderDirectionEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResBlocksDto, any>({
        path: `/admin/blocks`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetComputedHashprices
     * @request GET:/admin/hashprices
     * @secure
     */
    getComputedHashprices: (
      query: {
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
        orderBy: GetComputedHashpricesParamsOrderByEnum;
        orderDirection: GetComputedHashpricesParamsOrderDirectionEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResComputedHashpricesDto, any>({
        path: `/admin/hashprices`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetOpportunityRewards
     * @request GET:/admin/{opportunityId}/rewards
     * @secure
     */
    getOpportunityRewards: (
      opportunityId: string,
      query: {
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
        orderBy: GetOpportunityRewardsParamsOrderByEnum;
        orderDirection: GetOpportunityRewardsParamsOrderDirectionEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResOpportunityRewardsDto, any>({
        path: `/admin/${opportunityId}/rewards`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetRewardDetails
     * @request GET:/admin/rewards/{rewardId}
     * @secure
     */
    getRewardDetails: (rewardId: string, params: RequestParams = {}) =>
      this.request<ResRewardDetails, any>({
        path: `/admin/rewards/${rewardId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetUsers
     * @request GET:/admin/users
     * @secure
     */
    getUsers: (
      query: {
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
        orderBy: GetUsersParamsOrderByEnum;
        orderDirection: GetUsersParamsOrderDirectionEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResUsersDto, any>({
        path: `/admin/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name CreateAdminUser
     * @request POST:/admin/users/admin
     * @secure
     */
    createAdminUser: (data: ReqAdminUserDto, params: RequestParams = {}) =>
      this.request<ResAdminUserDto, any>({
        path: `/admin/users/admin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetExchangeRatesFromEur
     * @request POST:/admin/exchange-rates
     * @secure
     */
    getExchangeRatesFromEur: (data: ReqExchangeRatesDto, params: RequestParams = {}) =>
      this.request<ResExchangeRatesFromEur, any>({
        path: `/admin/exchange-rates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetPrediction
     * @request GET:/admin/predictions/{range}
     * @secure
     */
    getPrediction: (range: number, params: RequestParams = {}) =>
      this.request<ResPredictionsPayload, any>({
        path: `/admin/predictions/${range}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetHistoricalHashrates
     * @request GET:/admin/hashrate/history
     * @secure
     */
    getHistoricalHashrates: (params: RequestParams = {}) =>
      this.request<ResHistoricalPayload, any>({
        path: `/admin/hashrate/history`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  miningProxy = {
    /**
     * No description
     *
     * @tags mining-proxy
     * @name DiagnoseProxyConnection
     * @request GET:/mining-proxy/diagnose-proxy-connection
     */
    diagnoseProxyConnection: (
      query: {
        apiKey: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/mining-proxy/diagnose-proxy-connection`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetAllGroups
     * @request GET:/mining-proxy/groups/members
     */
    getAllGroups: (params: RequestParams = {}) =>
      this.request<GroupDto[], any>({
        path: `/mining-proxy/groups/members`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name AddGroup
     * @request POST:/mining-proxy/groups/members
     */
    addGroup: (data: GroupAddRequestDto, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/mining-proxy/groups/members`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetContracts
     * @request GET:/mining-proxy/contracts/list
     */
    getContracts: (params: RequestParams = {}) =>
      this.request<ContractByGroupDto, any>({
        path: `/mining-proxy/contracts/list`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetGroupHashrate
     * @request GET:/mining-proxy/groups/members/{uuid}/hashrate
     */
    getGroupHashrate: (uuid: string, params: RequestParams = {}) =>
      this.request<HashrateResponseDto, any>({
        path: `/mining-proxy/groups/members/${uuid}/hashrate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetGroupStatus
     * @request GET:/mining-proxy/groups/members/{uuid}/status
     */
    getGroupStatus: (uuid: string, params: RequestParams = {}) =>
      this.request<StatusResponseDto, any>({
        path: `/mining-proxy/groups/members/${uuid}/status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetGroupWorkers
     * @request GET:/mining-proxy/groups/members/{uuid}/workers
     */
    getGroupWorkers: (uuid: string, params: RequestParams = {}) =>
      this.request<WorkersResponseDto, any>({
        path: `/mining-proxy/groups/members/${uuid}/workers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name AddContract
     * @request POST:/mining-proxy/contracts
     */
    addContract: (data: ContractAddRequestDto, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/mining-proxy/contracts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name UpdateGroupDefaultPool
     * @request POST:/mining-proxy/groups/members/updateDefault
     */
    updateGroupDefaultPool: (data: GroupUpdateRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mining-proxy/groups/members/updateDefault`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetPoolCredentials
     * @request GET:/mining-proxy/proxy/pool-credentials
     */
    getPoolCredentials: (params: RequestParams = {}) =>
      this.request<MiningPoolCredentialsResponseDto, any>({
        path: `/mining-proxy/proxy/pool-credentials`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name SetupMiningProxy
     * @request POST:/mining-proxy/proxy/setup
     */
    setupMiningProxy: (data: MiningPoolCredentialsDto, params: RequestParams = {}) =>
      this.request<ProxyGroupResponseDto, any>({
        path: `/mining-proxy/proxy/setup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetProxyMiningAddress
     * @request GET:/mining-proxy/proxy/mining-address
     */
    getProxyMiningAddress: (params: RequestParams = {}) =>
      this.request<GetProxyMiningAddressResponseDto, any>({
        path: `/mining-proxy/proxy/mining-address`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mining-proxy
     * @name GetProxyHashrate
     * @request GET:/mining-proxy/proxy/hashrate
     */
    getProxyHashrate: (params: RequestParams = {}) =>
      this.request<ProxyHashrateResponseDto, any>({
        path: `/mining-proxy/proxy/hashrate`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  enterpriseSettings = {
    /**
     * No description
     *
     * @tags admin
     * @name GetEnterpriseSettingsAndManagers
     * @request GET:/enterprise-settings
     * @secure
     */
    getEnterpriseSettingsAndManagers: (
      query: {
        organizationId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResEnterpriseSettingDto, any>({
        path: `/enterprise-settings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name EnterpriseSettingsControllerUpdate
     * @request PATCH:/enterprise-settings/{id}
     * @secure
     */
    enterpriseSettingsControllerUpdate: (id: string, data: EnterpriseSettingDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/enterprise-settings/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  csv = {
    /**
     * No description
     *
     * @name GetOrganizationTransactionsCsv
     * @request GET:/csv/organization-transactions
     */
    getOrganizationTransactionsCsv: (
      query: {
        includeDeposits: boolean;
        includeWithdrawals: boolean;
        includeInternal: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTransactionCsvResponse, any>({
        path: `/csv/organization-transactions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOpportunityTransactionsCsvAdmin
     * @request GET:/csv/admin/opportunity-transactions
     */
    getOpportunityTransactionsCsvAdmin: (
      query: {
        opportunityId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTransactionCsvResponse, any>({
        path: `/csv/admin/opportunity-transactions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOpportunityTransactionsAccountingCsv
     * @request GET:/csv/admin/opportunity-transactions-accounting
     */
    getOpportunityTransactionsAccountingCsv: (
      query: {
        opportunityId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTransactionCsvResponse, any>({
        path: `/csv/admin/opportunity-transactions-accounting`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOpportunityTransactionsCsvUser
     * @request GET:/csv/opportunity-transactions
     */
    getOpportunityTransactionsCsvUser: (
      query: {
        opportunityId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTransactionCsvResponse, any>({
        path: `/csv/opportunity-transactions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  migration = {
    /**
     * No description
     *
     * @name RunMigration
     * @request GET:/migration/run
     */
    runMigration: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/migration/run`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RevertMigration
     * @request GET:/migration/revert
     */
    revertMigration: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/migration/revert`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
